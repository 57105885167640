import { useState } from "react";
import { Button } from "@mui/material";
import cx from "classnames";

import { QuestionInfo } from "../../types/common";
import styles from "./styles.module.scss";

type Props = {
  questions: QuestionInfo[];
};

const difficultyMap = (difficulty: string) => {
  switch (difficulty) {
    case "易":
      return "BEGIN";
    case "中":
      return "INTERMEDIATE";
    case "難":
      return "EXPERT";
    default:
      return "";
  }
};

const ErrorQuestionsList = ({ questions }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.errorQuestionsList}>
      <div className={styles.errorQuestionsList__description}>
        此測驗答錯率排名前 10 的試題
      </div>
      <div className={styles.list}>
        {questions.slice(0, isOpen ? 10 : 5).map((question, index) => (
          <div key={question.questionId} className={styles.question}>
            <div className={styles.question__header}>
              <div className={styles.question__index}>#{index + 1}</div>
              <div className={styles.question__group}>
                {question.quesGroupName}
              </div>
              <div className={styles.question__knowledge}>
                知識向度：{question.knowledgeName}
              </div>
              <div
                className={cx(
                  styles.question__difficulty,
                  styles[
                    `question__difficulty--${difficultyMap(
                      question.difficultyName
                    )}`
                  ]
                )}
              >
                {question.difficultyName}
              </div>
              <div className={styles.question__errorRate}>
                答錯率 {question.errorRate}%
              </div>
            </div>
            <div className={styles.question__content}>
              <img src={question.questionContentUrl} alt="" />
            </div>
          </div>
        ))}
        {!isOpen && questions.length > 5 && (
          <Button
            className={styles.button}
            color="inherit"
            variant="outlined"
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <img src="/assets/chevron-down.svg" alt="" />
            展開全部
          </Button>
        )}
      </div>
    </div>
  );
};

export default ErrorQuestionsList;
