import { Fragment } from "react";
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  styled,
} from "@mui/material";

import { ReviewQuestionList } from "../../../types/wrongQuestions";

import styles from "./styles.module.scss";
import CloseButton from "../../CloseButton/CloseButton";
import { optionToNumeric, questionTypeMapper } from "../../../utils/helpers";

const StyledDialogTitle = styled(DialogTitle)(() => ({
  padding: "40px 48px 32px",
}));
const StyledDialogContent = styled(DialogContent)(() => ({
  padding: "40px 48px",
}));

const removeOptionParentheses = (options: string[]) =>
  options.map((option) => option.replace("(", "").replace(")", ""));
const isAnswerMatched = (option: string, answers: number[] | string[]) => {
  const option_ = optionToNumeric(option);
  const answers_ = answers.map(optionToNumeric);
  return answers_.includes(option_);
};

type Props = {
  questions: ReviewQuestionList["questionDetails"];
  title: string;
  onClose: () => void;
} & DialogProps;

const WrongQuestionDialog = ({
  questions,
  title,
  onClose,
  ...props
}: Props) => {
  return (
    <Dialog
      PaperProps={{
        style: {
          minWidth: "792px",
        },
      }}
      onClose={onClose}
      {...props}
    >
      <StyledDialogTitle>
        {title}
        <CloseButton onClose={onClose} />
      </StyledDialogTitle>
      <StyledDialogContent>
        <div className={styles.dialog__questions}>
          {questions.map((question) => (
            <div className={styles.question} key={question.questionId}>
              <div className={styles.question__chip}>題目</div>
              <div className={styles.question__content}>
                <img src={question.questionContentUrl} alt="" />
              </div>
              <div className={styles.question__chip}>答案</div>
              <div className={styles.question__content}>
                <img src={question.answerContentUrl} alt="" />
              </div>
              <div className={styles.question__chip}>解析</div>
              <div className={styles.question__content}>
                <img src={question.analyzeContentUrl} alt="" />
              </div>
              <div className={styles.question__divider} />
              {question.answerInfos.map((answerInfo) => {
                const options =
                  answerInfo.answerType === "YN"
                    ? ["○", "╳"]
                    : removeOptionParentheses(answerInfo.option || []);
                return (
                  <Fragment key={answerInfo.index}>
                    <div className={styles.question__type}>
                      {questionTypeMapper(answerInfo.answerType)}
                    </div>
                    <div className={styles.question__answer}>
                      <div
                        className={styles["question__answer__label--student"]}
                      >
                        學生作答
                      </div>
                      <div className={styles.answer__options}>
                        {options.map((option) => (
                          <div
                            key={option}
                            className={
                              isAnswerMatched(
                                option,
                                answerInfo?.userAnswer || []
                              )
                                ? styles["answer__options__student"]
                                : ""
                            }
                          >
                            {option}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className={styles.question__answer}>
                      <div
                        className={styles["question__answer__label--correct"]}
                      >
                        正確答案
                      </div>
                      <div className={styles.answer__options}>
                        {options.map((option) => (
                          <div
                            key={option}
                            className={
                              isAnswerMatched(
                                option,
                                removeOptionParentheses(
                                  answerInfo?.answer || []
                                )
                              )
                                ? styles["answer__options__correct"]
                                : ""
                            }
                          >
                            {option}
                          </div>
                        ))}
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </div>
          ))}
        </div>
      </StyledDialogContent>
    </Dialog>
  );
};

export default WrongQuestionDialog;
