import styles from "./styles.module.scss";

type Props = {
  children: React.ReactNode;
  title: string;
  width?: string;
  toolbar?: React.ReactNode;
};

const Block = ({ children, title, width = "100%", toolbar }: Props) => {
  return (
    <div className={styles.block} style={{ width }}>
      <div className={styles.block__title}>
        <div className={styles.block__block} />
        <span>{title}</span>
        <div className={styles.block__toolbar}>{toolbar}</div>
      </div>
      {children}
    </div>
  );
};

export default Block;
