import styles from "./styles.module.scss";

const ReportGenerating = () => {
  return (
    <div className={styles.reportGenerating}>
      <img src="/assets/report-generating.svg" alt="" />
      <div className={styles.reportGenerating__progressBar}></div>
      <div className={styles.reportGenerating__title}>報表生成中</div>
      <div>正在計算資料</div>
    </div>
  );
};

export default ReportGenerating;
