/* eslint-disable no-console */
// duplicated from onePaper
import axios from "axios";

import { checkNaniLinkLogin } from "../services/oneClub";
import { Response } from "../types/common";

axios.defaults.timeout = 20000;

const formatParams = (data: any) => {
  Object.entries(data).forEach(([key, value]) => {
    if (value === "") {
      delete data[key];
    }
  });
  return data;
};

axios.interceptors.request.use(
  (config) => {
    config.headers = {
      "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
      Authorization: checkNaniLinkLogin()
        ? JSON.parse(checkNaniLinkLogin()).jwt
        : null,
      "Content-Type": "application/json",
      ...config.headers,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response: any) => {
    // if (response.state !== 'success') Promise.reject(response.message);
    return response;
  },
  (err) => {
    if (err && err.response) {
      switch (err.response.status) {
        case 401:
          err.response = "Token 過期,請重新登入";
          break;
        default:
          err.response = `${err.response.status}`;
          break;
      }
    } else {
      console.log("連結錯誤");
    }
    return Promise.reject(err.response);
  }
);

export const get = <T>(url: string, params = {}, config = {}) =>
  axios
    .get<T>(url, {
      params: formatParams(params),
      ...config,
    })
    .then((res) => res.data);

export const post = <T>(url: string, data = {}, config = {}) =>
  axios.post<Response<T>>(url, data, config).then((res) => res.data);

// eslint-disable-next-line import/no-anonymous-default-export
export default { get, post };
