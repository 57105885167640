import dayjs from "dayjs";

export const getCookie = (name: string) => {
  const v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
};

export const formatSecond = (seconds: number) => {
  let seconds_ = Math.round(seconds);
  const minutes = Math.trunc(seconds_ / 60);
  seconds_ -= minutes * 60;
  return `${minutes}:${seconds_.toString().padStart(2, "0")}`;
};

export const formatDateTimeRange = (dateStart: any, dateEnd: any) => {
  if (typeof dateStart !== "string" || typeof dateEnd !== "string") {
    return `${String(dateStart)} ~ ${String(dateEnd)}`;
  }
  const start = dayjs(dateStart);
  const end = dayjs(dateEnd);
  if (start.date() === end.date()) {
    return `${start.format("YYYY/MM/DD HH:mm")}~${end.format("HH:mm")}`;
  } else {
    return `${start.format("YYYY/MM/DD HH:mm")} ~ ${end.format(
      "YYYY/MM/DD HH:mm"
    )}`;
  }
};

export const downloadByUrl = (fileUrl: string) => {
  const a = document.createElement("a");
  a.href = fileUrl;
  a.click();
  a.remove();
};

export const setEndDateTime = (date: string) =>
  dayjs(date)
    .set("hour", 23)
    .set("minute", 59)
    .set("second", 59)
    .set("millisecond", 999)
    .toISOString();

export const questionTypeMapper = (type: string) => {
  switch (type) {
    case "FL":
      return "填充題";
    case "GR":
      return "題組題";
    case "MT":
      return "多選題";
    case "MX":
      return "混合題";
    case "SS":
      return "單一選擇題";
    case "WR":
      return "非選題";
    case "YN":
      return "是非題";
    default:
      return type;
  }
};

export const optionToNumeric = (option: string | number) => {
  switch (option) {
    case "①":
    case "A":
      return "1";
    case "②":
    case "B":
      return "2";
    case "③":
    case "C":
      return "3";
    case "④":
    case "D":
      return "4";
    case "⑤":
    case "E":
      return "5";
    case "⑥":
    case "F":
      return "6";
    case "⑦":
    case "G":
      return "7";
    case "⑧":
    case "H":
      return "8";
    case "⑨":
    case "I":
      return "9";
    case "⑩":
    case "J":
      return "10";
    default:
      return option.toString();
  }
};
