import { useMemo } from "react";
import { Tooltip } from "@mui/material";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";

import styles from "./styles.module.scss";
import { ExamReportResult } from "../../../types/statistics";

const BAR_CHART_COLOR = "#4c99a5";
const xAxisLabelMap = (range: number) => {
  return `${(range - 1) * 10}-${range * 10}`;
};

type Props = {
  statistics: ExamReportResult;
};

const ExamDistributionChart = ({ statistics }: Props) => {
  const barChartData = useMemo(
    () =>
      statistics?.distributions?.map((distribution) => ({
        x: xAxisLabelMap(distribution.scoreRatePercentRange),
        y: distribution.scoreRateCount,
      })) || [],
    [statistics]
  );
  const chartOptions: ApexOptions = {
    title: {
      text: "人數",
      offsetY: 7,
      style: {
        color: "#8b90a0",
        fontWeight: 400,
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    legend: {
      showForSingleSeries: true,
      fontSize: "14px",
      markers: {
        radius: 0,
        offsetX: -2,
        offsetY: -1,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
    },
    tooltip: {
      y: {
        formatter: (value) => value.toFixed(0),
      },
    },
    xaxis: {
      labels: {
        style: {
          colors: "#8b90a0",
          fontSize: "14px",
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#8b90a0",
          fontSize: "14px",
        },
        formatter: (value) => value.toFixed(0),
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "35%",
      },
    },
    dataLabels: {
      background: {
        enabled: false,
      },
      offsetY: -15,
      enabled: true,
      style: {
        fontSize: "14px",
        fontWeight: 400,
      },
    },
    stroke: {
      colors: [BAR_CHART_COLOR],
      width: 1,
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
  };
  const chartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        type: "bar",
        name: "人數",
        data: barChartData,
        color: BAR_CHART_COLOR,
      },
    ],
    [barChartData]
  );

  return (
    <div className={styles.container}>
      <div className={styles.chart}>
        <Chart options={chartOptions} series={chartSeries} height="276px" />
      </div>
      <div className={styles.xAxisLabel}>
        得分率（％）
        <Tooltip title="得分除以總分">
          <img src="/assets/question-mark.svg" alt="" />
        </Tooltip>
      </div>
    </div>
  );
};

export default ExamDistributionChart;
