import { Dispatch, SetStateAction } from "react";
import { TablePagination } from "@mui/material";

type Props = {
  count: number;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  rowsPerPageOptions?: number[];
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
};
const CustomPagination = ({
  count,
  page,
  setPage,
  rowsPerPageOptions,
  rowsPerPage,
  setRowsPerPage,
}: Props) => {
  return (
    <TablePagination
      count={count}
      page={page}
      onPageChange={(_, value) => setPage(value)}
      rowsPerPageOptions={rowsPerPageOptions}
      rowsPerPage={rowsPerPage}
      labelRowsPerPage="每頁筆數"
      onRowsPerPageChange={(e) => setRowsPerPage(Number(e.target.value))}
      component="div"
    />
  );
};

export default CustomPagination;
