import { useState, useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  Autocomplete,
  Button,
  Checkbox,
  MenuItem,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import dayjs from "dayjs";
import queryString from "query-string";

import styles from "./styles.module.scss";
import searchStyles from "../../styles/module/search.module.scss";
import { DATE_FORMAT, STORAGE_KEY } from "../../utils/constants";
import { EduSubject, Exam } from "../../types/common";
import {
  getEduSubject,
  getTranscript,
  getTranscriptDownload,
} from "../../api/exam";
import {
  useSomeHaveSeatNo,
  useClassInfo,
  useExamList,
} from "../../utils/hooks";
import { QueryExam } from "../../types/transcript";
import { downloadByUrl } from "../../utils/helpers";

const SELECT_ALL = "SELECT_ALL";
const DEFAULT_START_TIME = dayjs().subtract(2, "week").format(DATE_FORMAT);
const DEFAULT_END_TIME = dayjs().format(DATE_FORMAT);

const StickyLeftCell = styled(TableCell)(
  ({ withSeatNo }: { withSeatNo: boolean }) => ({
    position: "sticky",
    left: 0,
    width: withSeatNo ? "192px" : "112px",
    paddingTop: "0",
    paddingBottom: "0",
    zIndex: "10",
    borderRight: "2px solid #d5d7de",
    "> .MuiTableCell-root": {
      borderBottom: "none",
    },
  })
);
const StickyRightCell = styled(TableCell)(() => ({
  position: "sticky",
  right: 0,
  width: "114px",
  borderLeft: "2px solid #d5d7de",
}));
const HeadCell = styled(TableCell)(({ scroll }: { scroll: boolean }) => ({
  width: scroll ? "90px" : "auto",
  cursor: "pointer",
}));
const ActionButton = styled(Button)(() => ({
  minWidth: "82px",
  padding: "4px 12px",
}));

const NoExams = () => (
  <div className={styles.noExams}>
    <img src="/assets/forest.svg" alt="" />
    <span>尚無測驗成績</span>
  </div>
);

const Transcript = () => {
  const location = useLocation();
  const haveFirstSearched = useRef(false);
  const [eduSubjectList, setEduSubjectList] = useState<EduSubject[]>([]);
  const formik = useFormik({
    initialValues: {
      eduSubject: "" as EduSubject["code"],
      startTime:
        sessionStorage.getItem(STORAGE_KEY.startTime) || DEFAULT_START_TIME,
      endTime: sessionStorage.getItem(STORAGE_KEY.endTime) || DEFAULT_END_TIME,
      exams: [] as Exam[],
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const { eduSubject, startTime, endTime, exams } = formik.values;
  const examIdsQuery = useMemo(
    () =>
      exams.length
        ? "&examIds=" + exams.map((exam) => exam.examId).join(",")
        : "",
    [exams]
  );
  const { examList } = useExamList({ eduSubject, startTime, endTime });
  const { classInfo } = useClassInfo();
  const [selectedEduSubject, setSelectedEduSubject] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [transcript, setTranscript] = useState<QueryExam | null>(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const noExams = useMemo(
    () => Boolean(transcript && !transcript.examInfos.length),
    [transcript]
  );
  const { isSomeHaveSeatNo } = useSomeHaveSeatNo(transcript?.students || []);

  const handleSubmit = (values: any) => {
    const { eduSubject } = values;
    const { classId, institutionId } = queryString.parse(location.search);
    if (typeof classId !== "string" || typeof institutionId !== "string") {
      console.error("取得資訊錯誤！");
      return;
    }

    localStorage.setItem("eduSubject", eduSubject);
    sessionStorage.setItem(STORAGE_KEY.startTime, startTime);
    sessionStorage.setItem(STORAGE_KEY.endTime, endTime);
    setSelectedEduSubject(
      eduSubjectList.find((item) => item.code === eduSubject)?.name || ""
    );

    setIsLoading(true);
    const body = {
      classId,
      institutionId,
      examIds: exams.map((exam) => exam.examId),
    };
    getTranscript(body)
      .then(setTranscript)
      .finally(() => setIsLoading(false));
  };

  const handleDownload = () => {
    const { classId, institutionId } = queryString.parse(location.search);
    if (typeof classId !== "string" || typeof institutionId !== "string") {
      console.error("取得資訊錯誤！");
      return;
    }

    setIsDownloading(true);
    const body = {
      classId,
      institutionId,
      examIds: exams.map((exam) => exam.examId),
    };
    getTranscriptDownload(body)
      .then((res) => {
        if (res?.fileUrl) {
          downloadByUrl(res.fileUrl);
        }
      })
      .finally(() => setIsDownloading(false));
  };

  useEffect(() => {
    const { classId } = queryString.parse(location.search);
    if (typeof classId !== "string") return;
    getEduSubject(classId).then((res) => {
      setEduSubjectList(res);
      formik.setFieldValue(
        "eduSubject",
        localStorage.getItem("eduSubject") || res?.[0]?.code || ""
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  // 不讓使用者清除日期
  useEffect(() => {
    if (startTime === "") {
      formik.setFieldValue("startTime", endTime);
    }
    if (endTime === "") {
      formik.setFieldValue("endTime", startTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, endTime]);

  useEffect(() => {
    formik.setFieldValue("exams", examList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examList]);

  // 預設搜尋
  useEffect(() => {
    if (!exams.length) return;
    if (!haveFirstSearched.current) {
      haveFirstSearched.current = true;
      handleSubmit(formik.values);
    }
  }, [exams]);

  return (
    <>
      <div className={styles.banner}>
        <img src="/assets/transcript-banner.svg" alt="" />
        <div className={styles.banner__title}>成績總表</div>
        <div className={styles.banner__class}>{classInfo?.name || ""}</div>
      </div>
      <div className={styles.container}>
        <div className={styles.search}>
          <form
            className={searchStyles.search__inputs}
            onSubmit={formik.handleSubmit}
          >
            <TextField
              select
              name="eduSubject"
              label="學制科目"
              sx={{ width: 200 }}
              value={formik.values.eduSubject}
              onChange={(e) => {
                formik.setFieldValue("eduSubject", e.target.value);
                formik.setFieldValue("exams", []);
              }}
            >
              {eduSubjectList.map((item) => (
                <MenuItem key={item.code} value={item.code}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              type="date"
              name="startTime"
              label="開始日期"
              sx={{ width: 200 }}
              value={formik.values.startTime}
              onChange={formik.handleChange}
              InputProps={{ inputProps: { max: formik.values.endTime } }}
            />
            <TextField
              type="date"
              name="endTime"
              label="結束日期"
              sx={{ width: 200 }}
              value={formik.values.endTime}
              onChange={formik.handleChange}
              InputProps={{ inputProps: { min: formik.values.startTime } }}
            />
            <Autocomplete
              multiple
              sx={{ width: 412 }}
              limitTags={1}
              disableCloseOnSelect
              noOptionsText="查無測驗"
              options={
                examList.length > 0
                  ? [
                      { examId: SELECT_ALL, name: "所有測驗", createTime: "" },
                      ...examList,
                    ]
                  : []
              }
              isOptionEqualToValue={(option, value) =>
                option.examId === value.examId
              }
              getOptionLabel={(option) =>
                option.examId === SELECT_ALL
                  ? "所有測驗"
                  : `${dayjs(option.createTime).format("YYYY/MM/DD")} ${
                      option.name
                    }`
              }
              renderInput={(params) => <TextField {...params} label="測驗" />}
              value={formik.values.exams}
              onChange={(_, values, __, details) => {
                const isSelectAll = details?.option.examId === SELECT_ALL;
                const isClearAll =
                  isSelectAll && formik.values.exams.length === examList.length;
                if (isClearAll) {
                  formik.setFieldValue("exams", []);
                } else {
                  formik.setFieldValue(
                    "exams",
                    isSelectAll ? examList : values
                  );
                }
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    style={{ height: "24px" }}
                    checked={
                      option.examId === SELECT_ALL
                        ? formik.values.exams.length === examList.length
                        : selected
                    }
                  />
                  {option.name}
                </li>
              )}
            />
            <LoadingButton
              type="submit"
              disabled={formik.values.exams.length === 0}
              loading={isLoading}
            >
              查詢
            </LoadingButton>
          </form>
        </div>
        {transcript ? (
          <div className={styles.table}>
            <div className={styles.table__toolbar}>
              <div className={styles.table__eduSubject}>
                {selectedEduSubject}
              </div>
              <div className={styles.table__buttons}>
                <LoadingButton
                  variant="outlined"
                  className={styles.table__buttons__button}
                  disabled={noExams}
                  loading={isDownloading}
                  loadingPosition="start"
                  onClick={handleDownload}
                  startIcon={<img src="/assets/download.svg" alt="" />}
                >
                  下載成績
                </LoadingButton>
                <Button
                  variant="outlined"
                  className={styles.table__buttons__button}
                  onClick={() =>
                    window.open(`/class${location.search}${examIdsQuery}`)
                  }
                  disabled={noExams}
                >
                  <img src="/assets/file-medical.svg" alt="" />
                  班級分析報表
                </Button>
                <div className={styles.table__buttons__divider} />
                <Button
                  variant="outlined"
                  className={styles.table__buttons__button}
                  onClick={() =>
                    window.open(`/review${location.search}${examIdsQuery}`)
                  }
                  disabled={noExams}
                >
                  <img src="/assets/rocket.svg" alt="" />
                  派發錯題
                </Button>
              </div>
            </div>
            <TableContainer>
              <Table size="small" stickyHeader style={{ tableLayout: "fixed" }}>
                <TableHead>
                  <TableRow>
                    <StickyLeftCell withSeatNo={isSomeHaveSeatNo}>
                      {isSomeHaveSeatNo && <TableCell>座號</TableCell>}
                      <TableCell>姓名</TableCell>
                    </StickyLeftCell>
                    {noExams ? (
                      <TableCell />
                    ) : (
                      <>
                        {transcript.examInfos.map((exam, _, arr) => (
                          <HeadCell key={exam.examId} scroll={arr.length > 9}>
                            <Tooltip title={exam.examName} arrow>
                              <div className={styles.table__examName}>
                                <span className={styles.table__examName__date}>
                                  {dayjs(exam.createDate).format("M/D")}
                                </span>
                                <span className={styles.table__examName__name}>
                                  {exam.examName}
                                </span>
                              </div>
                            </Tooltip>
                          </HeadCell>
                        ))}
                        <StickyRightCell />
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transcript?.students?.map((student, i, arr) => (
                    <TableRow key={student.userId}>
                      <StickyLeftCell withSeatNo={isSomeHaveSeatNo}>
                        {isSomeHaveSeatNo && (
                          <TableCell className={styles.table__cell}>
                            {student.seatNo || "-"}
                          </TableCell>
                        )}
                        <TableCell className={styles.table__cell}>
                          {student.name}
                        </TableCell>
                      </StickyLeftCell>
                      {noExams ? (
                        i === 0 && (
                          <TableCell
                            rowSpan={arr.length}
                            style={{ verticalAlign: "top" }}
                          >
                            <NoExams />
                          </TableCell>
                        )
                      ) : (
                        <>
                          {transcript.examInfos.map((exam) => (
                            <TableCell key={exam.examId} align="right">
                              {student.exams.find(
                                (exam_) => exam_.examId === exam.examId
                              )?.score ?? (
                                <span className={styles.table__notAnswered}>
                                  未作答
                                </span>
                              )}
                            </TableCell>
                          ))}
                          <StickyRightCell>
                            <ActionButton
                              variant="outlined"
                              onClick={() =>
                                window.open(
                                  `/student${location.search}${examIdsQuery}&userId=${student.userId}&jobId=${transcript.jobId}`
                                )
                              }
                              disabled={!student.exams.length}
                            >
                              個人報表
                            </ActionButton>
                          </StickyRightCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <NoExams />
        )}
      </div>
    </>
  );
};

export default Transcript;
