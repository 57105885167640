import { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { enqueueSnackbar } from "notistack";
import styles from "./styles.module.scss";
import TreeSelect from "./TreeSelect";
import LoadingProgress from "../../components/LoadingProgress/LoadingProgress";
import queryString from "query-string";
import { STORAGE_KEY } from "../../utils/constants";
import { useUserInfo } from "../../utils/hooks";
import {
  getReviewStudentEduSubject,
  getReviewStudentRange,
  getReviewStudentAssign,
  getReviewStudentCalculate,
} from "../../api/review/review";
import {
  ReviewStudentEduSubject,
  ReviewStudentRangeData,
  ReviewStudentRangePayload,
  ReviewStudentAssignPayload,
} from "../../types/wrongQuestions";
import { InstitutionInfo } from "../../types/common";
import FullSizeLoading from "../../components/fullSizeLoading/fullSizeLoading";
import { useInterval } from "usehooks-ts";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
))(() => ({
  fontSize: "14px",
  color: "#242C3F",
  backgroundColor: "#fff",
  boxShadow: "0px 10px 24px rgba(0, 0, 0, 0.1)",
  borderRadius: "12px",
}));

const EDUCATION = ["國小", "國中", "高中"];

const NOW = "現有錯題";
const HISTORY = "歷史錯題";
const TYPES = [NOW, HISTORY];

const flattenNodes = (nodes: ReviewStudentRangeData[]) => {
  const result: ReviewStudentRangeData[] = [];

  for (const node of nodes) {
    result.push(node);
    if (node.children) {
      result.push(...flattenNodes(node.children));
    }
  }
  return result;
};

const ExtraLearning = () => {
  const location = useLocation();
  const { userInfo } = useUserInfo();

  const [type, setType] = useState(NOW);
  const [grade, setGrade] = useState("");
  const [subject, setSubject] = useState<string>("");
  const [eduSubject, setEduSubject] = useState<ReviewStudentEduSubject>([]);
  const [treeData, setTreeData] = useState<ReviewStudentRangeData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedNodes, setSelectedNodes] = useState<string[]>([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isCalculating, setIsCalculating] = useState(true);

  const service = localStorage.getItem(STORAGE_KEY.sourceService) || "oneLink";
  const { institutionId } = queryString.parse(location.search);

  // 取得學制科目
  const educationList = useMemo(() => {
    const sortedObj = eduSubject.sort((a, b) => {
      return (
        EDUCATION.indexOf(a.education.name) -
        EDUCATION.indexOf(b.education.name)
      );
    });

    const sortList = sortedObj.map((item) => {
      return item.education;
    });

    return sortList;
  }, [eduSubject]);

  const foundSubject = eduSubject?.find((item) => {
    return item?.education?.code === grade;
  })?.subjects;

  const handleSubjectClick = (name: string) => {
    setSelectedNodes([]);
    setSubject(name);
  };

  // 調整成 tree 格式
  const chapterOptions = (data: ReviewStudentRangeData) => {
    if (!data.rangeId) {
      data.rangeId = data?.name;
    }
    if (data.children) {
      for (let item of data.children) {
        chapterOptions(item);
      }
    }

    return data;
  };

  const handleGoToTest = () => {
    if (typeof institutionId === "string") {
      if (userInfo?.id) {
        const body: ReviewStudentAssignPayload = {
          rangeIds: chapterList,
          eduSubject: `${grade}${subject}`,
          institutionId,
          service,
          userName: userInfo?.id,
          isLatestReview: type === NOW,
        };

        setSubmitLoading(true);

        getReviewStudentAssign(body)
          .then((res) => {
            const { examId, userName } = res[0];

            if (res) {
              window.open(
                `${process.env.REACT_APP_ONEEXAM_URL}/user/answer/${examId}/${userName}`,
                "_self"
              );
            } else {
              enqueueSnackbar("前往測卷失敗!", { variant: "error" });
            }
          })
          .finally(() => setSubmitLoading(false));
      }
    }
  };

  const selectRangeContent = () => {
    if (subject === "") {
      return (
        <div className={styles.noChooseSubjectWarning}>請先選擇學制科目</div>
      );
    }

    if (isLoading) {
      return <LoadingProgress />;
    }

    if (!treeData) {
      return (
        <div className={styles.emptyData}>
          <img src="/assets/no-data-cactus.svg" alt="" />
          <div className={styles.emptyData__info}>
            此範圍無錯題，請變更學制科目
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div className={styles.chosenNumber}>已選擇 {selectedNumber} 題</div>
          <TreeSelect
            treeData={chapterOptions(treeData)}
            selectedNodes={selectedNodes}
            setSelectedNodes={setSelectedNodes}
          />
        </>
      );
    }
  };

  // 已選的題目(將章節刪除，只留下課程)
  const selectedChapter = (list: string[]) => {
    const findEvent = (
      treeData: ReviewStudentRangeData,
      item: string,
      currentArray: string[]
    ) => {
      if (treeData?.rangeId === item) {
        if (!treeData?.children) {
          return [...currentArray, item];
        } else {
          treeData.children.forEach((child) => {
            currentArray = findEvent(child, item, currentArray);
          });
        }
      } else {
        treeData?.children?.forEach((child) => {
          currentArray = findEvent(child, item, currentArray);
        });
      }
      return currentArray;
    };

    let newArray: string[] = [];

    list.forEach((listItem) => {
      if (treeData) {
        newArray = findEvent(treeData, listItem, newArray);
      }
    });

    return newArray;
  };

  const chapterList = useMemo(() => {
    return Array.from(new Set(selectedChapter(selectedNodes)));
  }, [selectedNodes]);

  // 已選題目的數量
  const selectedNumber = useMemo(() => {
    if (!treeData || !selectedNodes.length) return 0;

    return flattenNodes([treeData]).reduce((total, curr) => {
      return selectedChapter(selectedNodes).includes(curr.rangeId)
        ? total + curr.total
        : total;
    }, 0);
  }, [treeData, selectedNodes]);

  const handleCalculate = useCallback(() => {
    if (typeof institutionId === "string") {
      const body: InstitutionInfo = {
        institutionId,
        service,
      };
      getReviewStudentCalculate(body).then((res) => {
        setIsCalculating(res.data.calculateStatus === "Pending");
      });
    }
  }, [institutionId, service]);

  useInterval(handleCalculate, isCalculating ? 3000 : null);

  useEffect(handleCalculate, [handleCalculate]);

  useEffect(() => {
    if (isCalculating || typeof institutionId !== "string") return;
    const body: InstitutionInfo = {
      institutionId,
      service,
    };
    getReviewStudentEduSubject(body).then((res) => {
      if (res) {
        setEduSubject(res);
      } else {
        enqueueSnackbar("取得學制科目失敗!", { variant: "error" });
      }
    });
  }, [isCalculating, institutionId, service]);

  useEffect(() => {
    if (educationList.length > 0) {
      setGrade(educationList[0].code);
    }
  }, [educationList]);

  useEffect(() => {
    if (typeof institutionId === "string" && subject && grade) {
      const body: ReviewStudentRangePayload = {
        institutionId,
        service,
        eduSubject: `${grade}${subject}`,
        isLatestReview: type === NOW,
      };
      setIsLoading(true);

      getReviewStudentRange(body)
        .then((res) => {
          if (res.isSuccess) {
            const { data } = res;
            setTreeData(data);
          } else {
            setTreeData(null);
            enqueueSnackbar("取得學制範圍失敗!", { variant: "error" });
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [subject]);

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.header__title}>自主練習錯題</div>

        <div className={styles.header__info}>
          選擇要複習的錯題範圍，並前往測驗
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.step__title}>第一步：選擇複習類型</div>
          <div className={styles.step__info}>類型</div>
          <div className={styles.step__buttonGroup}>
            {TYPES.map((typeItem) => (
              <Button
                variant={type === typeItem ? "contained" : "outlined"}
                onClick={() => setType(typeItem)}
                sx={{
                  minWidth: "96px",
                  fontSize: "16px",
                  padding: "12px 8px",
                  lineHeight: "24px",
                  height: "48px",
                }}
                key={typeItem}
              >
                {typeItem}
              </Button>
            ))}
            <CustomTooltip
              disableFocusListener
              arrow={false}
              title={
                <>
                  <div>・現有錯題：學生還沒有練到會的題目</div>
                  <div>・歷史錯題：所有學生過去所做錯的題目</div>
                </>
              }
              placement="right"
            >
              <img
                src="/assets/question-icon.svg"
                alt=""
                className={styles.questionIcon}
              />
            </CustomTooltip>
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.step__title}>第二步：選擇學制科目</div>
          <div className={styles.step__info}>學制</div>
          <div className={styles.step__buttonGroup}>
            {educationList.map((item) => {
              return (
                <Button
                  key={item.code}
                  variant={grade === item.code ? "contained" : "outlined"}
                  sx={{
                    minWidth: "96px",
                    fontSize: "16px",
                    padding: "12px 8px",
                    lineHeight: "24px",
                    height: "48px",
                    marginTop: "12px",
                  }}
                  onClick={() => {
                    setGrade(item.code);
                    setSubject("");
                  }}
                >
                  {item.name}
                </Button>
              );
            })}
          </div>
          <div className={styles.step__info}>科目</div>
          <div className={styles.step__buttonGroup}>
            {foundSubject?.map((sub) => {
              return (
                <Button
                  key={sub.code}
                  variant={subject === sub.code ? "contained" : "outlined"}
                  onClick={() => handleSubjectClick(sub.code)}
                  sx={{
                    minWidth: "96px",
                    fontSize: "16px",
                    padding: "12px 8px",
                    lineHeight: "24px",
                    height: "48px",
                    marginTop: "12px",
                  }}
                >
                  {sub.name}
                </Button>
              );
            })}
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.step__title}>第三步：選擇範圍</div>
          {selectRangeContent()}
        </div>
        <div className={styles.footerButtonWrapper}>
          <Button
            sx={{
              fontSize: "16px",
              color: "#fff",
              backgroundColor: "#EC7963",
              padding: "12px 16px",
              "&:hover": {
                backgroundColor: "#EC7963",
                color: "#fff",
              },
            }}
            disabled={chapterList.length === 0}
            onClick={() => handleGoToTest()}
          >
            前往測驗
          </Button>
        </div>
      </div>
      {submitLoading && <FullSizeLoading />}
    </div>
  );
};

export default ExtraLearning;
