import Api from "..";
import { MyOrganization } from "../../types/common";

const API_URL = process.env.REACT_APP_API_URL_ONECLASS;

export const getMyOrganization = async (institutionId: string) =>
  await Api.get<MyOrganization>(`${API_URL}/organizations/${institutionId}/me`);

export const getClassInfo = async (classId: string) =>
  await Api.get<any>(`${API_URL}/groups/${classId}`);
