import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Pagination, Tooltip } from "@mui/material";
import queryString from "query-string";

import styles from "./styles.module.scss";
import { StudentInfo } from "../../../types/common";
import { ClassReportResult } from "../../../types/statistics";
import { useSomeHaveSeatNo } from "../../../utils/hooks";

type Props = {
  students: StudentInfo[];
  examIds: string[];
  jobId: ClassReportResult["jobId"];
};

const STUDENTS_PER_PAGE = 10;

const ClassStudentList = ({ students, examIds, jobId }: Props) => {
  const location = useLocation();
  const [page, setPage] = useState(1);
  const { isSomeHaveSeatNo } = useSomeHaveSeatNo(students || []);

  const handleChangePage = (_: any, value: number) => {
    setPage(value);
  };

  const directToStudentReport = (userId: string) => {
    const qs = queryString.parse(location.search);
    const newQs = queryString.stringify({
      ...qs,
      userId,
      jobId,
      examIds: examIds.join(","),
    });
    window.open(`/student?${newQs}`);
  };

  return (
    <div className={styles.table}>
      <div className={styles["table__row--head"]}>
        {isSomeHaveSeatNo && <div className={styles.seatNo}>座號</div>}
        <div className={styles.name}>姓名</div>
        <div className={styles.avgRate}>
          <span>平均得分率</span>
          <Tooltip title="每場測驗得分除以總分，加總取平均值">
            <img src="/assets/question-mark.svg" alt="" />
          </Tooltip>
        </div>
        <div className={styles.action} />
      </div>
      <div className={styles.table__body}>
        {students
          .slice((page - 1) * STUDENTS_PER_PAGE, page * STUDENTS_PER_PAGE)
          .map((student) => (
            <div key={student.userId} className={styles["table__row--body"]}>
              {isSomeHaveSeatNo && (
                <div className={styles.seatNo}>{student.seatNo || "-"}</div>
              )}
              <div className={styles.name}>{student.name}</div>
              <div className={styles.avgRate}>
                {student.isCreated ? (
                  `${student.avgScoreRate}%`
                ) : (
                  <span className={styles.noRecord}>無交卷紀錄</span>
                )}
              </div>
              <div className={styles.action}>
                <Button
                  variant="outlined"
                  color="inherit"
                  sx={{ padding: "4px 12px", height: "32px" }}
                  disabled={!student.isCreated}
                  onClick={() => directToStudentReport(student.userId)}
                >
                  個人報表
                </Button>
              </div>
            </div>
          ))}
      </div>
      <div className={styles.table__pagination}>
        <Pagination
          shape="rounded"
          count={Math.ceil(students.length / STUDENTS_PER_PAGE)}
          page={page}
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
};

export default ClassStudentList;
