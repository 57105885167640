import styles from "./styles.module.scss";

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.header__wrapper}>
        <img src="/assets/logo.svg" alt="" className={styles.header__logo} />
      </div>
    </header>
  );
};

export default Header;
