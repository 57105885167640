import { useState } from "react";
import { Button, Pagination, Tooltip } from "@mui/material";
import dayjs from "dayjs";

import { StudentExamInfo } from "../../types/common";
import styles from "./styles.module.scss";
import { formatSecond } from "../../utils/helpers";

const medalMap = (pr: number) => {
  if (pr > 75) {
    return "gold";
  } else if (pr > 25 && pr <= 75) {
    return "silver";
  } else {
    return "bronze";
  }
};

const EXAMS_PER_PAGE = 10;

type Props = {
  userName: string;
  exams: StudentExamInfo[];
  isTeacher: boolean;
};

const ExamList = ({ userName, exams, isTeacher }: Props) => {
  const [page, setPage] = useState(1);

  const handleChangePage = (_: any, value: number) => {
    setPage(value);
  };

  return (
    <div className={styles.table}>
      <div className={styles["table__row--head"]}>
        <div className={styles.testTime}>測驗時間</div>
        <div className={styles.name}>測驗名稱</div>
        <div className={styles.scoreRate}>
          <span>得分率</span>
          <Tooltip title="得分除以總分">
            <img src="/assets/question-mark.svg" alt="" />
          </Tooltip>
        </div>
        <div
          className={styles.pr}
          style={isTeacher ? {} : { justifyContent: "center" }}
        >
          {isTeacher ? (
            <>
              <span>班級 PR 值</span>
              <Tooltip title="班級排名百分比">
                <img src="/assets/question-mark.svg" alt="" />
              </Tooltip>
            </>
          ) : (
            "等級"
          )}
        </div>
        <div className={styles.spendTime}>花費時長/測驗時長</div>
        <div className={styles.action} />
      </div>
      <div className={styles.table__body}>
        {exams
          .slice((page - 1) * EXAMS_PER_PAGE, page * EXAMS_PER_PAGE)
          .map((exam) => (
            <div key={exam.examId + exam.createTime} className={styles["table__row--body"]}>
              <div className={styles.testTime}>
                {dayjs(exam.createTime).format("YYYY/MM/DD HH:mm")}
              </div>
              <div className={styles.name}>{exam.name}</div>
              <div className={styles.scoreRate}>
                {exam.isCreated ? (
                  `${exam.scoreRate}%`
                ) : (
                  <span className={styles.noRecord}>無交卷紀錄</span>
                )}
              </div>
              <div
                className={styles.pr}
                style={isTeacher ? {} : { justifyContent: "center" }}
              >
                {exam.isCreated ? (
                  isTeacher ? (
                    exam.pr
                  ) : (
                    <img
                      src={`/assets/medal-${medalMap(exam.pr)}.svg`}
                      alt=""
                    />
                  )
                ) : (
                  <span className={styles.noRecord}>
                    {isTeacher ? "-" : "無交卷紀錄"}
                  </span>
                )}
              </div>
              <div className={styles.spendTime}>
                {exam.isCreated
                  ? `${formatSecond(exam.sumTime)}/${exam.examPeriod}:00`
                  : "-"}
              </div>
              <div className={styles.action}>
                <Button
                  variant={exam.isCreated ? "contained" : "outlined"}
                  color="inherit"
                  sx={{ padding: "4px 12px", height: "32px" }}
                  disabled={!exam.isCreated}
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_ONEEXAM_URL}/user/report/${exam.examId}/${userName}`
                    )
                  }
                >
                  測驗結果
                </Button>
              </div>
            </div>
          ))}
      </div>
      <div className={styles.table__pagination}>
        <Pagination
          shape="rounded"
          count={Math.ceil(exams.length / EXAMS_PER_PAGE)}
          page={page}
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
};

export default ExamList;
