import { StudentFilterPayload } from "./../../types/statistics";
import Api from "..";
import {
  ClassReportResult,
  ClassReportResultPayload,
  ExamReportResult,
  StudentFilterResult,
  StudentReportResult,
  StudentReportResultPayload,
} from "../../types/statistics";

const API_URL = process.env.REACT_APP_API_URL;

export const getClassStatistics = async (payload: ClassReportResultPayload) =>
  (
    await Api.post<ClassReportResult>(
      `${API_URL}/api/Statistics/class`,
      payload
    )
  ).data;

export const getExamStatistics = async (payload: ClassReportResultPayload) =>
  (await Api.post<ExamReportResult>(`${API_URL}/api/Statistics/exam`, payload))
    .data;

export const getStudentStatistics = async (
  payload: StudentReportResultPayload
) =>
  (
    await Api.post<StudentReportResult>(
      `${API_URL}/api/Statistics/student`,
      payload
    )
  ).data;

export const getStudentFilterList = async (payload: StudentFilterPayload) =>
  (
    await Api.post<StudentFilterResult[]>(
      `${API_URL}/api/Statistics/studentfilter`,
      payload
    )
  ).data;
