import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  MouseEvent,
} from "react";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import TreeItem from "@mui/lab/TreeItem";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";

import { ReviewStudentRangeData } from "../../types/wrongQuestions";

const ALL_RANGE = "所有範圍";

interface RenderTree {
  rangeId: string;
  name: string;
  children?: RenderTree[] | null;
}

interface Props {
  treeData: ReviewStudentRangeData;
  selectedNodes: string[];
  setSelectedNodes: Dispatch<SetStateAction<string[]>>;
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    maxWidth: "100%",
    backgroundColor: "#fff",
    "& > .MuiTreeItem-root > .MuiTreeItem-content": {
      padding: "0px",
      "& > .MuiTreeItem-iconContainer": {
        display: "none",
      },
    },
    "& > .MuiTreeItem-root": {
      border: "1px solid #E4E7EC",
      borderRadius: "10px",
      "& > .MuiCollapse-root": {
        borderTop: "1px solid #E4E7EC",
        marginLeft: "0px",
        "& > .MuiCollapse-wrapper > .MuiCollapse-wrapperInner > .MuiTreeItem-root":
          {
            borderBottom: "1px solid #E4E7EC",
            "&:last-child": {
              borderBottom: "none",
            },
          },
      },
    },
  },
  "@global": {
    ".Mui-selected": {
      backgroundColor: "#fff",
    },
    ".MuiCheckbox-root": {
      padding: "0px",
      margin: "16px 16px 16px 20px",
    },
    ".MuiTreeItem-content > .MuiTreeItem-label": {
      padding: "0px",
    },
  },
  content: {
    flexDirection: "row-reverse",
  },
}));

const bfsSearch = (graph: ReviewStudentRangeData[], targetId: string) => {
  let queue = [...graph];

  while (queue.length > 0) {
    const currNode = queue.shift() as ReviewStudentRangeData;

    if (currNode.rangeId === targetId) {
      return currNode;
    }
    if (currNode.children) {
      queue = [...queue, ...currNode.children];
    }
  }

  return [];
};

const TreeSelect = ({ treeData, selectedNodes, setSelectedNodes }: Props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<string[]>([ALL_RANGE]);
  const getAllIds = (
    node: ReviewStudentRangeData,
    idList: string[] = []
  ): string[] => {
    idList = [...idList, node.rangeId];
    if (node.children) {
      node?.children.forEach((child) => {
        idList = getAllIds(child, idList);
      });
    }
    return idList;
  };

  const getAllChild = (id: string) => {
    return getAllIds(bfsSearch([treeData], id) as ReviewStudentRangeData);
  };

  const getAllParent = (id: string, list: string[] = []): string[] => {
    const node = bfsSearch([treeData], id) as ReviewStudentRangeData;

    if (node.parent) {
      list = [...list, node.parent];

      return getAllParent(node.parent, list);
    }

    return list;
  };

  const isAllChildrenChecked = (
    node: ReviewStudentRangeData,
    list: string[]
  ) => {
    const allChild: string[] = getAllChild(node.rangeId);
    const nodeIdIndex = allChild.indexOf(node.rangeId);
    allChild.splice(nodeIdIndex, 1);

    return allChild.every((nodeId) =>
      selectedNodes.concat(list).includes(nodeId)
    );
  };

  // 取得全部資料的id，組成一個新的 Array
  const extractIds = (data: ReviewStudentRangeData[]): string[] => {
    let ids: string[] = [];

    if (data) {
      for (let item of data) {
        if (item.rangeId) {
          ids = [...ids, item.rangeId];
        }

        if (item.children && item.children.length > 0) {
          ids = ids.concat(extractIds(item.children));
        }
      }
    }

    return ids;
  };
  const idsArray = extractIds([treeData]);

  const handleNodeSelect = (
    event: MouseEvent<HTMLButtonElement>,
    nodeId: string
  ) => {
    event.stopPropagation();
    const allChild: string[] = getAllChild(nodeId);
    const parent: string[] = getAllParent(nodeId);

    if (selectedNodes.includes(nodeId)) {
      // 取消勾選
      if (nodeId === ALL_RANGE) {
        setSelectedNodes([]);
      } else {
        setSelectedNodes((prevSelectedNodes) =>
          prevSelectedNodes.filter(
            (id) => !allChild.concat(parent).includes(id)
          )
        );
      }
    } else {
      // 勾選
      if (nodeId === ALL_RANGE) {
        setSelectedNodes(idsArray);
      } else {
        let toBeChecked: string[] = allChild;
        for (let selectId of parent) {
          if (
            isAllChildrenChecked(
              bfsSearch([treeData], selectId) as ReviewStudentRangeData,
              toBeChecked
            )
          ) {
            toBeChecked = [...toBeChecked, selectId];
          }
        }
        setSelectedNodes((prevSelectedNodes) =>
          [...prevSelectedNodes].concat(toBeChecked)
        );
      }
    }
  };

  // 其他選項全部勾選的話，要將 selectAll 勾起
  // 全選情況下取消任何一個項目，要將 selectAll 一起取消
  useEffect(() => {
    if (selectedNodes.length === idsArray?.length - 1) {
      setSelectedNodes(idsArray);
    }
    if (
      selectedNodes.includes(ALL_RANGE) &&
      selectedNodes.length !== idsArray.length
    ) {
      const newNode = [...selectedNodes];
      let index = newNode.indexOf(ALL_RANGE);

      newNode.splice(index, 1);

      setSelectedNodes(newNode);
    }
  }, [selectedNodes]);

  const goThroughAllNodes = (
    node: RenderTree,
    map: { [key: string]: string[] } = {}
  ) => {
    if (!node.children) {
      return null;
    }

    map[node.rangeId] = getAllChildren(node)?.splice(1);

    for (let childNode of node.children) {
      goThroughAllNodes(childNode, map);
    }

    return map;
  };

  const getAllChildren = (
    childNode: RenderTree | null,
    collectedNodes: string[] = []
  ) => {
    if (childNode === null) return collectedNodes;

    collectedNodes.push(childNode.rangeId);

    if (Array.isArray(childNode.children)) {
      for (const node of childNode.children) {
        getAllChildren(node, collectedNodes);
      }
    }

    return collectedNodes;
  };

  const renderTree = (node: ReviewStudentRangeData, level = -1) => {
    const indeterminate = () => {
      const indeterminateCheck =
        goThroughAllNodes(treeData)?.[node.rangeId]?.some(
          (childNodeId: string) => {
            return selectedNodes.includes(childNodeId);
          }
        ) || false;

      return indeterminateCheck;
    };

    const handleItemClick = (id: string) => {
      if (id === ALL_RANGE) {
        return;
      }

      if (!expanded.includes(id)) {
        setExpanded((prev) => [...prev, id]);
      } else {
        const filterItem = expanded.filter((item) => item !== id);
        setExpanded(filterItem);
      }
    };

    return (
      <TreeItem
        key={node.rangeId}
        nodeId={node.rangeId}
        classes={{
          content: classes.content,
        }}
        sx={{
          ".MuiTreeItem-group": { ml: 0 },
          ".MuiTreeItem-content": {
            pl: `${level * 32}px`,
          },
        }}
        onClick={() => handleItemClick(node.rangeId)}
        label={
          <div>
            <Typography variant="body2" component={"div"}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  checked={selectedNodes.includes(node.rangeId)}
                  indeterminate={
                    !selectedNodes.includes(node.rangeId) && indeterminate()
                  }
                  disableRipple
                  onClick={(event) => handleNodeSelect(event, node.rangeId)}
                  style={{ color: "#121232" }}
                />
                <div>
                  <span
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {node.name}
                  </span>
                  <span
                    style={{
                      color: "#8B90A0",
                      fontSize: "16px",
                      marginLeft: "4px",
                    }}
                  >
                    {`(${node.total ?? 0} 題)`}
                  </span>
                </div>
              </div>
            </Typography>
          </div>
        }
      >
        {Array.isArray(node.children) &&
          node.children.map((node) => renderTree(node, level + 1))}
      </TreeItem>
    );
  };

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandLessIcon />}
      defaultExpanded={[ALL_RANGE]}
      defaultExpandIcon={<ExpandMoreIcon />}
      selected={[]}
      expanded={expanded}
    >
      {renderTree(treeData)}
    </TreeView>
  );
};

export default TreeSelect;
