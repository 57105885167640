import { IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

type Props = {
  onClose: () => void;
  isInDialog?: boolean;
};

const CloseButton = ({ onClose, isInDialog = true }: Props) => {
  return (
    <IconButton
      onClick={onClose}
      sx={{
        ...(isInDialog
          ? { position: "absolute", top: "16px", right: "16px" }
          : {}),
      }}
    >
      <CloseIcon
        sx={{
          fill: "#a1a4b1",
        }}
      />
    </IconButton>
  );
};

export default CloseButton;
