import { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import queryString from "query-string";

import { Exam, MyOrganization } from "../types/common";
import { getExam } from "../api/exam";
import { getClassInfo, getMyOrganization } from "../api/oneclass";
import { setEndDateTime } from "./helpers";

export const useExamList = ({
  eduSubject,
  startTime,
  endTime,
}: {
  eduSubject: string;
  startTime: string;
  endTime: string;
}) => {
  const location = useLocation();
  const [examList, setExamList] = useState<Exam[]>([]);

  useEffect(() => {
    const { classId, institutionId } = queryString.parse(location.search);
    if (
      typeof classId !== "string" ||
      typeof institutionId !== "string" ||
      eduSubject === "" ||
      startTime === "" ||
      endTime === "" ||
      dayjs(startTime).isAfter(endTime)
    ) {
      return;
    }
    getExam({
      eduSubject,
      startTime,
      endTime: setEndDateTime(endTime),
      institutionId,
      classId,
    }).then(setExamList);
  }, [eduSubject, startTime, endTime, location.search]);

  return { examList };
};

export const useUserInfo = () => {
  const location = useLocation();
  const [userInfo, setUserInfo] = useState<MyOrganization["data"] | null>(null);

  useEffect(() => {
    const { institutionId } = queryString.parse(location.search);
    if (typeof institutionId === "string") {
      getMyOrganization(institutionId).then((res) => {
        if (res.status === "failure") {
          console.error("取得資訊錯誤！");
        } else {
          setUserInfo(res.data);
        }
      });
    }
  }, [location.search]);

  return { userInfo };
};

export const useClassInfo = () => {
  const location = useLocation();
  const [classInfo, setClassInfo] = useState<any>(null);

  useEffect(() => {
    const { classId } = queryString.parse(location.search);
    if (typeof classId === "string") {
      getClassInfo(classId).then((res) => {
        if (res.status === "failure") {
          console.error("取得班級資訊錯誤！");
        } else {
          setClassInfo(res.data);
        }
      });
    }
  }, [location.search]);

  return { classInfo };
};

export const useSomeHaveSeatNo = (students: Required<{ seatNo: string }>[]) => {
  const isSomeHaveSeatNo = useMemo(
    () =>
      students.length
        ? students.some((student) => Boolean(student.seatNo))
        : true,
    [students]
  );

  return { isSomeHaveSeatNo };
};
