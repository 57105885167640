import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  styled,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";

import { AssignReviewStudent } from "../../../types/wrongQuestions";
import CloseButton from "../../CloseButton/CloseButton";
import { Response } from "../../../types/common";

const StyledDialogTitle = styled(DialogTitle)(() => ({
  padding: "48px 60px 20px",
  color: "#505565",
}));
const StyledDialogContent = styled(DialogContent)(() => ({
  padding: "0 60px 68px",
  color: "#505565",
}));
const StyledDialogActions = styled(DialogActions)(() => ({
  padding: "0 24px 20px",
}));

type Props = {
  onClose: () => void;
  selectedStudents: AssignReviewStudent[];
  assignExams: () => Promise<void> | Promise<Response<string[]>>;
} & DialogProps;

const AssignExamDialog = ({
  onClose,
  selectedStudents,
  assignExams,
  ...props
}: Props) => {
  const [isAssigningExam, setIsAssigningExam] = useState(false);

  const handleSubmit = async () => {
    setIsAssigningExam(true);
    assignExams()
      .then((res) => {
        if (res?.isSuccess) {
          onClose();
          enqueueSnackbar("派卷成功", { variant: "success" });
        } else {
          console.error("派卷失敗！");
        }
      })
      .finally(() => setIsAssigningExam(false));
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          minWidth: "792px",
        },
      }}
      onClose={onClose}
      {...props}
    >
      <StyledDialogTitle>
        將派給 {selectedStudents.length} 位學生錯題測驗
        <CloseButton onClose={onClose} />
      </StyledDialogTitle>
      <StyledDialogContent>
        派卷後，學生將立即收到測驗。
        <br />
        派出的錯題數將以派卷當下為準。
      </StyledDialogContent>
      <StyledDialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          style={{ minWidth: "88px" }}
        >
          取消
        </Button>
        <LoadingButton onClick={handleSubmit} loading={isAssigningExam}>
          立即派出
        </LoadingButton>
      </StyledDialogActions>
    </Dialog>
  );
};

export default AssignExamDialog;
