import { useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import queryString from "query-string";

import styles from "./styles.module.scss";
import { ClassReportResult } from "../../../types/statistics";
import { ExamDistribution } from "../../../types/common";

const LINE_CHART_COLOR = "#ff9800";
const xAxisLabelMap = (distribution: ExamDistribution) => {
  if (!distribution.name) return "";
  const nameLength = distribution.name.length;
  let name = [distribution.name.slice(0, 4)];
  if (nameLength > 4 && nameLength < 8) {
    name.push(distribution.name.slice(4, 7));
  } else if (nameLength >= 8) {
    name.push(distribution.name.slice(4, 7) + "...");
  } else {
    name.push(" ");
  }
  return [distribution.createDate, ...name];
};
const columnsWidthPercentage = (n: number) => {
  const percentWidth = Math.round((24 / (902 / n)) * 100);
  return `${percentWidth}%`;
};

type Props = {
  statistics: ClassReportResult;
};

const ClassDistributionChart = ({ statistics }: Props) => {
  const location = useLocation();

  /**
   * apexcharts 預設會把箱形圖蓋過折線圖（包括點），所以在這裡調整順序
   */
  const adjustPlotOrder = () => {
    const boxPlot = document.querySelector(
      ".apexcharts-line-series.apexcharts-plot-series"
    );
    if (boxPlot) {
      boxPlot.remove();
      document
        .querySelector(".apexcharts-line-series.apexcharts-plot-series")
        ?.insertAdjacentElement("afterend", boxPlot);
    }
  };

  const boxPlotChartData = useMemo(
    () =>
      statistics?.distributions?.map((distribution) => ({
        x: xAxisLabelMap(distribution),
        y: [
          distribution.minByScoreRate,
          distribution.firstQuartileByScoreRate,
          distribution.medianByScoreRate,
          distribution.thirdQuartileByScoreRate,
          distribution.maxByScoreRate,
        ],
      })),
    [statistics]
  );
  const lineChartData = useMemo(
    () =>
      statistics?.distributions?.map((distribution) => ({
        x: xAxisLabelMap(distribution),
        y: distribution.avgScoreRate,
      })),
    [statistics]
  );
  const chartOptions: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      events: {
        mounted() {
          adjustPlotOrder();
        },
        updated() {
          adjustPlotOrder();
        },
        xAxisLabelClick: (_, __, config) => {
          const { classId, institutionId } = queryString.parse(location.search);
          const examId = statistics?.distributions?.[config.labelIndex]?.examId;
          if (
            typeof classId !== "string" ||
            typeof institutionId !== "string" ||
            !examId
          ) {
            return;
          }
          const qs = queryString.stringify({
            classId,
            institutionId,
            examIds: examId,
          });
          window.open(`/exam?${qs}`);
        },
      },
    },
    legend: {
      fontSize: "14px",
      itemMargin: { horizontal: 24, vertical: 36 },
      onItemClick: {
        toggleDataSeries: false,
      },
      markers: {
        offsetX: -5,
      },
    },
    markers: {
      colors: LINE_CHART_COLOR,
      size: 6,
      hover: {
        size: 6,
      },
    },
    xaxis: {
      labels: {
        style: {
          cssClass: styles.xAxisLabels,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      max: 100,
      min: 0,
      tickAmount: 5,
      labels: {
        style: {
          colors: "#8b90a0",
          fontSize: "14px",
        },
        formatter: (value) => value.toFixed(0),
      },
    },
    plotOptions: {
      bar: {
        columnWidth: columnsWidthPercentage(
          statistics?.distributions?.length || 0
        ),
      },
      boxPlot: {
        colors: {
          upper: "#eef5f6",
          lower: "#eef5f6",
        },
      },
    },
    stroke: {
      colors: ["#94c2c9", LINE_CHART_COLOR],
      width: 1,
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    tooltip: {
      custom: (option) => {
        if (!statistics?.distributions) return "";
        const {
          name,
          maxByScoreRate,
          thirdQuartileByScoreRate,
          medianByScoreRate,
          firstQuartileByScoreRate,
          minByScoreRate,
          avgScoreRate,
        } = statistics.distributions[option.dataPointIndex];
        return `<div class="customTooltip">
            <div class="customTooltip__title">${name}</div>
            <div class="customTooltip__list">
              <div class="customTooltip__item">
                <div class="customTooltip__dot customTooltip__darkGreen"></div>
                <div class="customTooltip__type">最高分</div>
                <div class="customTooltip__value">${maxByScoreRate}</div>
              </div>
              <div class="customTooltip__item">
                <div class="customTooltip__dot customTooltip__lightGreen"></div>
                <div class="customTooltip__type">前標</div>
                <div class="customTooltip__value">${thirdQuartileByScoreRate}</div>
              </div>
              <div class="customTooltip__item">
                <div class="customTooltip__dot customTooltip__darkGreen"></div>
                <div class="customTooltip__type">均標</div>
                <div class="customTooltip__value">${medianByScoreRate}</div>
              </div>
              <div class="customTooltip__item">
                <div class="customTooltip__dot customTooltip__lightGreen"></div>
                <div class="customTooltip__type">後標</div>
                <div class="customTooltip__value">${firstQuartileByScoreRate}</div>
              </div>
              <div class="customTooltip__item">
                <div class="customTooltip__dot customTooltip__darkGreen"></div>
                <div class="customTooltip__type">最低分</div>
                <div class="customTooltip__value">${minByScoreRate}</div>
              </div>
              <div class="customTooltip__item">
                <div class="customTooltip__dot customTooltip__orange"></div>
                <div class="customTooltip__type">平均得分率</div>
                <div class="customTooltip__value">${avgScoreRate}</div>
              </div>
            </div>
          </div>`;
      },
    },
  };
  const chartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        type: "boxPlot",
        name: "得分率",
        data: boxPlotChartData || [],
        color: "#dbebed",
      },
      {
        type: "line",
        name: "平均得分率",
        data: lineChartData || [],
        color: LINE_CHART_COLOR,
      },
    ],
    [boxPlotChartData, lineChartData]
  );

  return statistics.distributions ? (
    <>
      <div className={styles.yAxisTitle}>
        得分率（％）
        <Tooltip title="得分除以總分">
          <img src="/assets/question-mark.svg" alt="" />
        </Tooltip>
      </div>
      <Chart
        options={chartOptions}
        series={chartSeries}
        width={
          statistics.distributions.length <= 12
            ? 902
            : statistics.distributions.length * 70
        }
        height="380px"
      />
    </>
  ) : (
    <></>
  );
};

export default ClassDistributionChart;
