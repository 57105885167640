import { Shadows, createTheme } from "@mui/material";
import type {} from "@mui/lab/themeAugmentation";
import type {} from "@mui/x-data-grid/themeAugmentation";

const theme = createTheme({
  palette: {
    primary: { main: "#ec7963" },
    secondary: { main: "#121232" },
  },
  shadows: Array(25).fill("none") as Shadows,
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: "40px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#8B90A0",
          transform: "translate(14px, 10px)",
        },
        shrink: {
          transform: "translate(14px, -9px) scale(0.75)",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "rgba(145, 158, 171, 0.32)",
          borderRadius: "8px",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          paddingTop: "6px !important",
        },
        inputRoot: {
          paddingTop: "3px",
          overflow: "hidden",
          "& .MuiChip-root": {
            maxWidth: "60%",
          },
        },
        option: {
          '&[aria-selected="true"]': {
            backgroundColor: "#f2f4f8 !important",

            "&.Mui-focused": {
              backgroundColor: "#f2f4f8",
            },

            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: "26px",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        root: {
          minWidth: "52px",
          letterSpacing: 0,
          fontWeight: "normal",
        },
        contained: ({ _, theme }) => ({
          backgroundColor: theme.palette.secondary.main,
          color: "#fff",
          "&:hover": {
            backgroundColor: theme.palette.secondary.main,
          },
        }),
        outlined: ({ _, theme }) => ({
          color: theme.palette.secondary.main,
          borderColor: "#d5d7de",
          "&.Mui-disabled": {
            backgroundColor: "#f2f4f8",
            color: "#a1a4b1",
            border: "1px solid #d5d7de",
          },
          "&:hover": {
            backgroundColor: "inherit",
            border: "1px solid #2a2a47",
          },
        }),
        startIcon: {
          marginLeft: 0,
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: "contained",
        sx: {
          textWrap: 'nowrap'
        }
      },
    },
    MuiTooltip: {
      defaultProps: {
        placement: "top",
        arrow: true,
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: "#212b36",
          maxWidth: "calc(20em + 16px)",
          fontSize: "12px",
        },
        arrow: {
          color: "#212b36",
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
        rounded: {
          borderRadius: "8px",
        },
      },
    },
    MuiTableCell: {
      defaultProps: {
        align: "center",
      },
      styleOverrides: {
        root: {
          minWidth: "80px",
          color: "#454b5c",
          lineHeight: "24px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
        },
        head: {
          backgroundColor: "#fafafc",
          maxHeight: "82px",
        },
        body: {
          backgroundColor: "#fff",
          height: "56px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: "10px 33px",
          color: "#8b90a0",
          fontSize: "18px",
          lineHeight: "24px",
        },
      },
    },
    MuiDataGrid: {
      defaultProps: {
        headerHeight: 58,
        rowHeight: 56,
        autoHeight: true,
      },
      styleOverrides: {
        root: {
          border: "none",
        },
        columnHeaders: {
          borderRadius: 0,
        },
        columnHeader: {
          backgroundColor: "#fafafc",
          paddingLeft: "16px",
          paddingRight: "16px",
          "&:focus": {
            outline: "none",
          },
        },
        columnHeaderCheckbox: {
          paddingLeft: 0,
          paddingRight: 0,
        },
        iconSeparator: {
          display: "none",
        },
        row: {
          "&.Mui-selected": {
            backgroundColor: "unset",
            "&:hover": {
              backgroundColor: "#f4f4f4",
            },
          },
        },
        cellContent: {
          color: "inherit",
        },
        cell: {
          paddingLeft: "16px",
          paddingRight: "16px",
          "&:focus": {
            outline: "none",
          },
          "&:focus-within": {
            outline: "none",
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          height: "56px",
          borderBottom: "none",
          "& .Mui-disabled svg": {
            fill: "#97a1af",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#f2f4f8",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
          },
        },
      },
    },
  },
});

export default theme;
