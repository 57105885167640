import { Box, Tooltip } from "@mui/material";

type Props = {
  fileUrl: string;
};

const DownloadZip = ({ fileUrl }: Props) => {
  return (
    <Tooltip title="下載試卷">
      <Box
        sx={{
          display: "flex",
          marginLeft: "12px",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => window.open(fileUrl)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill="#3A4052"
            fill-rule="evenodd"
            d="M14.25 2.5a.25.25 0 0 0-.25-.25H7A2.75 2.75 0 0 0 4.25 5v14A2.75 2.75 0 0 0 7 21.75h10A2.75 2.75 0 0 0 19.75 19V9.147a.25.25 0 0 0-.25-.25H15a.75.75 0 0 1-.75-.75V2.5Zm-.219 10.664a.75.75 0 0 1 .938 1.172l-2.494 1.995a.747.747 0 0 1-.473.169h-.008a.747.747 0 0 1-.465-.166l-2.497-1.998a.75.75 0 0 1 .937-1.172l1.281 1.026v-3.44a.75.75 0 1 1 1.5 0v3.44l1.281-1.026Z"
            clip-rule="evenodd"
          />
          <path
            fill="#3A4052"
            d="M15.75 2.824c0-.184.193-.301.336-.186c.121.098.23.212.323.342l3.013 4.197c.068.096-.006.22-.124.22H16a.25.25 0 0 1-.25-.25V2.824Z"
          />
        </svg>
      </Box>
    </Tooltip>
  );
};

export default DownloadZip;
