import { useState } from "react";
import { Tab, Tabs } from "@mui/material";

import styles from "./styles.module.scss";
import WrongQuestionsBase from "./WrongQuestionBase";
import WrongQuestionExams from "./WrongQuestionExams";
import WrongQuestionForStudent from "./WrongQuestionForStudent";
import { useClassInfo, useUserInfo } from "../../utils/hooks";

const TABS = [
  { label: "錯題庫", value: 0 },
  { label: "錯題卷", value: 1 },
];

const WrongQuestionsReview = () => {
  const { classInfo } = useClassInfo();
  const { userInfo } = useUserInfo();
  const isTeacher = userInfo?.role === "staff";
  const isStudent = userInfo?.role === "customer";
  const [tabValue, setTabValue] = useState(0);

  return (
    <>
      <div className={styles.banner}>
        <img src="/assets/review-banner.svg" alt="" />
        <div className={styles.banner__title}>複習錯題</div>
        {isTeacher && (
          <div className={styles.banner__class}>{classInfo?.name || ""}</div>
        )}
      </div>
      <div className={styles.container}>
        {isTeacher && (
          <>
            <div className={styles.tabs}>
              <Tabs value={tabValue} onChange={(_, v) => setTabValue(v)}>
                {TABS.map((tab) => (
                  <Tab key={tab.value} label={tab.label} />
                ))}
              </Tabs>
            </div>
            {tabValue === TABS[0].value && <WrongQuestionsBase />}
            {tabValue === TABS[1].value && <WrongQuestionExams />}
          </>
        )}
        {isStudent && <WrongQuestionForStudent userInfo={userInfo} />}
      </div>
    </>
  );
};

export default WrongQuestionsReview;
