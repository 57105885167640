import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";

import "./styles/style.scss";
import router from "./router";
import theme from "./styles/mui-theme";
import { STORAGE_KEY } from "./utils/constants";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Layout from "./components/Layout/Layout";
import Snackbar from "./components/Snackbar/Snackbar";

enum SERVICES {
  onelink = "oneLink",
  onestudy = "oneStudy",
}

const App = () => {
  // 確認來源服務，供後端篩選資料給不同服務的使用者，
  // 只要從該服務進入 OneData，就會一直記著，直到從另一個服務進入才會覆蓋
  useEffect(() => {
    const referrer = document.referrer.toLowerCase();
    if (!referrer) return;
    const service = Object.keys(SERVICES).find((service) =>
      referrer.includes(service)
    ) as keyof typeof SERVICES;
    if (service) {
      localStorage.setItem(STORAGE_KEY.sourceService, SERVICES[service]);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        autoHideDuration={3000}
        Components={{
          success: Snackbar,
          error: Snackbar,
          warning: Snackbar,
          info: Snackbar,
          default: Snackbar,
        }}
      >
        <Header />
        <Layout>
          <RouterProvider router={router} />
        </Layout>
        <Footer />
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
