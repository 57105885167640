import styles from "./styles.module.scss";

const FullSizeLoading = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <img
          className={styles.content__image}
          src="/assets/rocket.gif"
          alt=""
        />
        <div className={styles.content__title}>正在前往測驗...</div>
      </div>
    </div>
  );
};

export default FullSizeLoading;
