import { useState, useEffect, useMemo, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Button, MenuItem, TextField } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useFormik } from "formik";
import dayjs from "dayjs";
import queryString from "query-string";
import EastIcon from "@mui/icons-material/East";

import styles from "./styles.module.scss";
import searchStyles from "../../../styles/module/search.module.scss";
import { EduSubject, MyOrganization } from "../../../types/common";
import DataGrid, {
  ROWS_PER_PAGE_OPTIONS,
} from "../../../components/DataGrid/DataGrid";
import {
  getReviewStudentExam,
  getReviewStudentFilter,
  getReviewStudentErrorNumber,
} from "../../../api/review/review";
import {
  ReviewExamForStudent,
  ReviewStudentFilterPayload,
  ReviewStudentExamPayload,
  SourceDetail,
  ReviewStudentErrorNumberPayload,
} from "../../../types/wrongQuestions";
import { STORAGE_KEY } from "../../../utils/constants";
import DownloadZip from "../../../components/WrongQuestion/DownloadZip/DownloadZip";

const DATE_FORMAT = "YYYY/MM/DD HH:mm";
const SELECT_ALL = "SELECT_ALL";

type Props = {
  userInfo: MyOrganization["data"];
};

const WrongQuestionForStudent = ({ userInfo }: Props) => {
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(ROWS_PER_PAGE_OPTIONS[0]);
  const [sourceList, setSourceList] = useState<SourceDetail[]>([]);
  const [eduSubjectList, setEduSubjectList] = useState<EduSubject[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [reviewStudentExam, setReviewStudentExam] = useState<
    ReviewExamForStudent[]
  >([]);
  const [errNumber, setErrNumber] = useState(0);
  const formik = useFormik({
    initialValues: {
      sourceName: SELECT_ALL,
      sourceClassId: "",
      sourceTeacherName: "",
      eduSubject: SELECT_ALL,
    },
    onSubmit: () => {
      handleSubmit();
    },
  });
  const { sourceClassId, sourceTeacherName, eduSubject } = formik.values;
  const dataGridColumns: GridColDef<ReviewExamForStudent>[] = useMemo(
    () => [
      {
        field: "examName",
        headerName: "測驗名稱",
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <div
            className={styles.table__examName}
            title={params.row.examName || ""}
          >
            <div className={styles.table__examName__name}>
              {params.row.examName}
            </div>
            {dayjs(params.row.createTime).isSame(dayjs(), "date") && (
              <div className={styles.table__examName__newTag}>New</div>
            )}
          </div>
        ),
      },
      {
        field: "eduSubjectName",
        headerName: "學制科目",
        width: 100,
        sortable: false,
      },
      {
        field: "questionTotal",
        headerName: "題數",
        width: 100,
        headerAlign: "right",
        align: "right",
        sortable: false,
      },
      {
        field: "startTime",
        headerName: "開始時間",
        width: 160,
        sortable: false,
        valueFormatter: (row) => dayjs(row.value).format(DATE_FORMAT),
      },
      {
        field: "endTime",
        headerName: "結束時間",
        width: 160,
        sortable: false,
        valueFormatter: (row) => dayjs(row.value).format(DATE_FORMAT),
      },
      {
        field: "source",
        headerName: "派卷來源",
        width: 160,
        sortable: false,
      },
      {
        field: "action",
        headerName: "",
        sortable: false,
        flex: 1,
        renderCell: (params) => (
          <>
            {params.row.isAbsent ? (
              <Button variant="outlined" disabled={true}>
                測驗逾期
              </Button>
            ) : params.row.isCreated ? (
              <Button
                variant="outlined"
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_ONEEXAM_URL}/user/report/${params.row.examId}/${userInfo.id}`
                  )
                }
              >
                測驗結果
              </Button>
            ) : (
              <Button
                sx={{
                  backgroundColor: "primary.main",
                  ":hover": {
                    backgroundColor: "primary.main",
                  },
                }}
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_ONEEXAM_URL}/user/answer/${params.row.examId}/${userInfo.id}`
                  )
                }
              >
                前往測驗
              </Button>
            )}
            {params.row?.paperDownloadPath && (
              <DownloadZip fileUrl={params.row.paperDownloadPath} />
            )}
          </>
        ),
      },
    ],
    [userInfo.id]
  );

  const handleSubmit = useCallback(() => {
    const { institutionId } = queryString.parse(location.search);
    if (typeof institutionId !== "string") {
      console.error("取得資訊錯誤！");
      return;
    }

    const body: ReviewStudentExamPayload = {
      institutionId,
      userName: userInfo.id,
      pageNumber: page + 1,
      pageSize,
      teacherName: sourceTeacherName,
      classId: sourceClassId,
      eduSubject: eduSubject === SELECT_ALL ? "" : eduSubject,
      service: localStorage.getItem(STORAGE_KEY.sourceService) || "",
    };
    getReviewStudentExam(body).then((res) => {
      const responseData = res?.reviewExams?.map((item) => {
        return {
          ...item,
          source: item.source === "/" ? "自主練習" : item.source,
        };
      });

      setReviewStudentExam(responseData || []);
      setTotalCount(res.totalCount);
    });
  }, [
    location.search,
    userInfo.id,
    sourceClassId,
    sourceTeacherName,
    eduSubject,
    page,
    pageSize,
  ]);

  useEffect(() => {
    const { institutionId } = queryString.parse(location.search);
    if (typeof institutionId !== "string") {
      console.error("取得資訊錯誤！");
      return;
    }
    const body: ReviewStudentFilterPayload = {
      institutionId,
      userName: userInfo.id,
    };
    getReviewStudentFilter(body).then((res) => {
      setSourceList(res.sources || []);
      setEduSubjectList(res.eduSubjects || []);
    });
  }, [location.search, userInfo.id]);

  useEffect(() => {
    handleSubmit();
  }, [handleSubmit]);

  // 取得學生錯題數量
  useEffect(() => {
    const { institutionId } = queryString.parse(location.search);
    const service = localStorage.getItem(STORAGE_KEY.sourceService) || "";
    if (typeof institutionId !== "string") {
      console.error("取得資訊錯誤！");
      return;
    }
    const body: ReviewStudentErrorNumberPayload = {
      institutionId,
      service,
    };
    getReviewStudentErrorNumber(body).then((res) => {
      setErrNumber(res.total);
    });
  }, []);

  const handleSelfLearningClick = () => {
    const { classId, institutionId } = queryString.parse(location.search);
    window.open(
      `/extraLearning?classId=${classId}&institutionId=${institutionId}`,
      "_blank"
    );
  };

  return (
    <>
      <div className={styles.selfLearningBanner}>
        <div className={styles.errorQuestionWrapper}>
          <img src="/assets/self-learning-fist.svg" alt="" />

          <div className={styles.errorQuestionWrapper__title}>
            <div className={styles.errorQuestionWrapper__number}>
              {errNumber > 0 ? (
                <div>
                  還有<span style={{ color: "#EC7963" }}> {errNumber} </span>
                  題錯題！
                </div>
              ) : (
                <div>太棒了！目前沒有任何錯題！</div>
              )}
            </div>
            <div className={styles.errorQuestionWrapper__slogan}>
              {errNumber > 0 ? (
                <div>一起來自主練習錯題，把它練習到會吧～</div>
              ) : (
                <div>繼續加油！</div>
              )}
            </div>
          </div>
        </div>

        <div
          role="button"
          onClick={() => handleSelfLearningClick()}
          className={styles.goToSelfLearningButton}
        >
          前往自主練習錯題
          <EastIcon
            sx={{
              fill: "#fff",
              width: "15px",
              marginLeft: "10px",
            }}
          />
        </div>
      </div>
      <div className={styles.myErrorQuestion}>我的錯題卷</div>
      <div className={styles.search}>
        <form className={searchStyles.search__inputs}>
          <TextField
            select
            name="source"
            label="派卷來源"
            sx={{ width: 200 }}
            value={formik.values.sourceName}
            onChange={(e) => {
              const { value } = e.target;
              const selectedSource = sourceList.find(
                (source) => source.sourceName === value
              );
              const {
                classId = "",
                sourceName = "",
                teacherName = "",
              } = selectedSource || {};
              formik.setFieldValue(
                "sourceName",
                value === SELECT_ALL ? SELECT_ALL : sourceName
              );
              formik.setFieldValue("sourceClassId", classId);
              formik.setFieldValue("sourceTeacherName", teacherName);
            }}
          >
            <MenuItem value={SELECT_ALL}>所有來源</MenuItem>
            {sourceList.map((item) => (
              <MenuItem key={item.sourceName} value={item.sourceName}>
                {item.sourceName}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            name="eduSubject"
            label="學制科目"
            sx={{ width: 200 }}
            value={formik.values.eduSubject}
            onChange={formik.handleChange}
          >
            <MenuItem value={SELECT_ALL}>所有學制科目</MenuItem>
            {eduSubjectList.map((item) => (
              <MenuItem key={item.code} value={item.code}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </form>
      </div>
      <DataGrid
        noDataText="尚無錯題卷"
        rows={reviewStudentExam}
        columns={dataGridColumns}
        getRowId={(row) => row.paperId}
        customPage={page}
        customSetPage={setPage}
        customPageSize={pageSize}
        customSetPageSize={setPageSize}
        customCount={totalCount}
      />
    </>
  );
};

export default WrongQuestionForStudent;
