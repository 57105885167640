import { createBrowserRouter } from "react-router-dom";
import ClassReport from "./containers/ClassReport";
import ExamReport from "./containers/ExamReport";
import StudentReport from "./containers/StudentReport";
import Transcript from "./containers/Transcript";
import WrongQuestionsReview from "./containers/WrongQuestionsReview";
import ExtraLeaning from "./containers/ExtraLearning";

const router = createBrowserRouter([
  { path: "/class", element: <ClassReport /> },
  { path: "/exam", element: <ExamReport /> },
  { path: "/student", element: <StudentReport /> },
  { path: "/transcript", element: <Transcript /> },
  { path: "/review", element: <WrongQuestionsReview /> },
  { path: "/extraLearning", element: <ExtraLeaning /> },
  { path: "*", element: <div style={{ height: "80vh" }}></div> },
]);

export default router;
