import Api from "..";
import { EduSubject, Exam, Response } from "../../types/common";
import {
  DownloadExam,
  QueryExam,
  QueryExamPayload,
} from "../../types/transcript";

const API_URL = process.env.REACT_APP_API_URL;

export const getEduSubject = async (classId: string) =>
  await Api.get<Response<EduSubject[]>>(
    `${API_URL}/api/Exam/edusubject/${classId}`
  ).then((res) => res.data);

export const getExam = async (params: {
  eduSubject: EduSubject["code"];
  startTime: string;
  endTime: string;
  institutionId: string;
  classId: string;
}) => (await Api.post<Exam[]>(`${API_URL}/api/Exam/filter`, params)).data;

export const getTranscript = async (payload: QueryExamPayload) =>
  (await Api.post<QueryExam>(`${API_URL}/api/Exam/query`, payload)).data;

export const getTranscriptDownload = async (payload: QueryExamPayload) =>
  (await Api.post<DownloadExam>(`${API_URL}/api/Exam/download`, payload)).data;
