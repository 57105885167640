import { useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Tooltip } from "@mui/material";

import styles from "./styles.module.scss";
import { StudentReportResult } from "../../../types/statistics";
import { StudentExamDistribution } from "../../../types/common";

const medalMap = (pr: number) => {
  if (pr > 75) {
    return "gold";
  } else if (pr > 25 && pr <= 75) {
    return "silver";
  } else {
    return "bronze";
  }
};

const BAR_CHART_COLOR = "#dbebed";
const xAxisLabelMap = (name: StudentExamDistribution["name"]) => {
  if (name?.length >= 8) {
    return name?.slice(0, 7) + "...";
  } else return name;
};

type Props = {
  statistics: StudentReportResult;
};

const StudentDistributionChartForStudent = ({ statistics }: Props) => {
  const labels = useMemo(
    () =>
      statistics?.distributions?.map((distribution) => distribution.name) || [],
    [statistics]
  );
  const data = useMemo(
    () =>
      statistics?.distributions?.map((distribution) =>
        distribution.isCreated ? distribution.scoreRate : 0
      ) || [],
    [statistics]
  );
  const options: Highcharts.Options = {
    title: {
      text: undefined,
    },
    chart: {
      scrollablePlotArea: {
        minWidth: statistics.distributions
          ? statistics.distributions.length <= 14
            ? 1100
            : statistics.distributions.length * 81
          : 1100,
      },
      height: 410,
    },
    legend: {
      itemStyle: {
        color: "#3a4052",
        fontSize: "14px",
        fontWeight: "400",
      },
      itemHoverStyle: {
        cursor: "default",
      },
      symbolRadius: 0,
      symbolPadding: 8,
      margin: 36,
    },
    xAxis: {
      scrollbar: {
        enabled: true,
      },
      categories: labels,
      labels: {
        rotation: 0,
        useHTML: true,
        formatter: function () {
          if (!statistics?.distributions) return "";
          const distribution = statistics.distributions[this.pos];
          return `
          <div class="highChartXAxisLabel">
            <div class="highChartXAxisLabel__text">
              <span class="highChartXAxisLabel__date">
                ${distribution.createDate}
              </span>
              <span class="highChartXAxisLabel__name">
                ${xAxisLabelMap(distribution.name)}
              </span>
            </div>
            ${
              distribution.isCreated
                ? `<img src=${`/assets/medal-${medalMap(
                    distribution.pr
                  )}.svg`} />`
                : `<span class="highChartXAxisLabel__noRecord">無交卷紀錄</span>`
            }
            </div>`;
        },
      },
    },
    yAxis: {
      title: undefined,
      max: 100,
      min: 0,
      tickAmount: 6,
      labels: {
        format: "{text}%",
        style: {
          color: "#8b90a0",
          fontSize: "14px",
        },
      },
    },
    series: [
      {
        name: "得分率",
        type: "column",
        data,
        color: BAR_CHART_COLOR,
      },
    ],
    plotOptions: {
      column: {
        pointWidth: 24,
        minPointLength: 2,
        states: {
          hover: {
            color: "#a5cbd1",
          },
        },
      },
      series: {
        events: {
          legendItemClick: function () {
            return false;
          },
        },
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: "transparent",
      formatter: function () {
        const index = this.points?.[0].point.index;
        if (typeof index !== "number" || !statistics?.distributions) return "";
        const { name, isCreated, scoreRate, pr } =
          statistics.distributions[index];
        return `<div class="customTooltip">
            <div class="customTooltip__title">${name}</div>
            <div class="customTooltip__list">
              <div class="customTooltip__item">
                <div class="customTooltip__dot customTooltip__lightGreen"></div>
                <div class="customTooltip__type">得分率</div>
                <div class="customTooltip__value">${
                  isCreated ? `${scoreRate}%` : "無交卷紀錄"
                }</div>
              </div>
              <div class="customTooltip__item">
                <div class="customTooltip__dot customTooltip__blue"></div>
                <div class="customTooltip__type">班級 PR 值</div>
                <div class="customTooltip__value">${isCreated ? pr : "-"}</div>
              </div>
            </div>
          </div>`;
      },
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
  };

  return (
    <>
      <div className={styles.yAxisTitle}>
        得分率（％）
        <Tooltip title="得分除以總分">
          <img src="/assets/question-mark.svg" alt="" />
        </Tooltip>
      </div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  );
};

export default StudentDistributionChartForStudent;
