import { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import queryString from "query-string";
import { useInterval } from "usehooks-ts";

import styles from "./styles.module.scss";
import Card from "../../components/Block/Block";
import { getExamStatistics } from "../../api/statistics";
import {
  ClassReportResultPayload,
  ExamReportResult,
} from "../../types/statistics";
import GroupCard from "../../components/GroupCard/GroupCard";
import ExamDistributionChart from "../../components/Charts/ExamDistributionChart/ExamDistributionChart";
import ErrorRateTable from "../../components/ErrorRateTable/ErrorRateTable";
import ErrorQuestionsList from "../../components/ErrorQuestionsList/ErrorQuestionsList";
import ExamStudentList from "../../components/StudentList/ExamStudentList/ExamStudentList";
import { formatDateTimeRange, formatSecond } from "../../utils/helpers";
import ReportGenerating from "../../components/ReportGenerating/ReportGenerating";

const GAP = 24;

const ExamReport = () => {
  const location = useLocation();
  const [statistics, setStatistics] = useState<ExamReportResult | null>(null);
  const groupCardData = useMemo(
    () => [
      {
        title: "交卷人數",
        value: (
          <>
            <span
              style={{ marginRight: "8px", fontSize: "24px", lineHeight: 1 }}
            >
              {statistics?.examBasicInfo?.finishedTotal}
            </span>
            <span style={{ color: "#8b90a0", fontSize: "14px" }}>
              /{statistics?.classInfo?.studentTotal}
            </span>
          </>
        ),
      },
      {
        title: "得分標準差",
        value: statistics?.examBasicInfo?.standardDev,
      },
      {
        title: "平均花費時長",
        value: (
          <>
            <span
              style={{ marginRight: "8px", fontSize: "24px", lineHeight: 1 }}
            >
              {formatSecond(statistics?.examBasicInfo?.avgSumTime || 0)}
            </span>
            <span style={{ color: "#8b90a0", fontSize: "14px" }}>
              /{statistics?.examBasicInfo?.examPeriod}:00
            </span>
          </>
        ),
      },
      {
        title: "最建議補強的知識向度",
        value: statistics?.examBasicInfo?.knowledgeName || "-",
      },
      {
        title: "最建議補強的認知向度",
        value: statistics?.examBasicInfo?.cognitiveName || "-",
      },
    ],
    [statistics]
  );

  const handleSubmit = () => {
    const { classId, institutionId, examIds } = queryString.parse(
      location.search
    );
    if (
      typeof classId !== "string" ||
      typeof institutionId !== "string" ||
      typeof examIds !== "string" ||
      !examIds
    ) {
      console.error("取得資訊錯誤！");
      return;
    }

    const body: ClassReportResultPayload = {
      classId,
      institutionId,
      examIds: examIds.split(","),
    };
    getExamStatistics(body).then(setStatistics);
  };

  useEffect(() => {
    handleSubmit();
  }, [location.search]);

  useInterval(
    () => {
      handleSubmit();
    },
    statistics?.calculateStatus === "Pending" ? 3000 : null
  );

  return (
    <div className={styles.container} style={{ gap: GAP }}>
      {statistics &&
      (statistics.calculateStatus === "Completed" ||
        !statistics.isPublished) ? (
        <>
          <div className={styles.about}>
            <div>
              <div className={styles.about__info}>
                <div className={styles.about__title}>
                  <span className={styles.about__title__type}>
                    班級測驗報表
                  </span>
                  <span>
                    {statistics?.classInfo?.className
                      ? `｜${statistics.classInfo.className}`
                      : ""}
                  </span>
                </div>
                <div className={styles.about__examName}>
                  測驗名稱-{statistics?.examBasicInfo?.name}
                </div>
                {/* <div className={styles.about__classInfo}>
                  <span>測驗範圍</span>
                  <span>{statistics?.examBasicInfo?.range}</span>
                </div> */}
                <div className={styles.about__classInfo}>
                  <span>測驗時間</span>
                  <span>
                    {formatDateTimeRange(
                      statistics?.examBasicInfo?.startTime,
                      statistics?.examBasicInfo?.endTime
                    )}
                  </span>
                </div>
                <div className={styles.about__classInfo}>
                  <span>測驗題數</span>
                  <span>{statistics?.examBasicInfo?.questionTotal}題</span>
                </div>
                <div className={styles.about__classInfo}>
                  <span>授課老師</span>
                  <span>{statistics?.classInfo?.teacherNames?.join("、")}</span>
                </div>
              </div>
              {statistics.isPublished ? (
                <div className={styles.about__score}>
                  <div className={styles.about__score__title}>平均得分</div>
                  <div className={styles.about__score__content}>
                    <img
                      className={styles.about__score__icon}
                      src="/assets/trophy.svg"
                      alt=""
                    />
                    <div className={styles.about__score__point}>
                      <span className={styles.about__score__point__numerator}>
                        {statistics?.examBasicInfo?.avgUserTotalScore}
                      </span>
                      分
                      <span className={styles.about__score__point__denominator}>
                        / {statistics?.examBasicInfo?.totalScore} 分
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles["about__score--notPublished"]}>
                  <img
                    className={styles.about__score__icon}
                    src="/assets/trophy.svg"
                    alt=""
                  />
                  <span>成績尚未公布</span>
                </div>
              )}
            </div>
            {statistics?.isPublished && <GroupCard data={groupCardData} />}
          </div>
          {statistics?.isPublished ? (
            <>
              <Card title="測驗成績分布">
                <ExamDistributionChart statistics={statistics} />
              </Card>
              <Card title="知識向度答錯率" width={`calc(50% - ${GAP / 2}px)`}>
                <ErrorRateTable
                  type="知識向度"
                  data={statistics.knowledgeErrorRates}
                />
              </Card>
              <Card title="認知向度答錯率" width={`calc(50% - ${GAP / 2}px)`}>
                <ErrorRateTable
                  type="認知向度"
                  data={statistics.cognitiveErrorRates}
                />
              </Card>
              {statistics.questionInfos?.length && (
                <Card title="錯題排行">
                  <ErrorQuestionsList questions={statistics.questionInfos} />
                </Card>
              )}
              {statistics.examBasicInfo && (
                <Card
                  title="學生列表"
                  toolbar={
                    <Button
                      onClick={() => window.open(`/review${location.search}`)}
                    >
                      前往派錯題卷
                    </Button>
                  }
                >
                  <ExamStudentList
                    students={statistics.studentInfos || []}
                    examId={statistics.examBasicInfo.examId}
                  />
                </Card>
              )}
            </>
          ) : (
            <div className={styles.notPublished}>
              <img src="/assets/report-empty.svg" alt="" />
              <div className={styles.notPublished__title}>尚無報表資料</div>
              <div>批改完畢並公布成績即可查看報表資料</div>
            </div>
          )}
        </>
      ) : (
        <ReportGenerating />
      )}
    </div>
  );
};

export default ExamReport;
