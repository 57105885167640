import { useState, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Button,
  Checkbox,
  MenuItem,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { GridColDef } from "@mui/x-data-grid";
import { useFormik } from "formik";
import dayjs from "dayjs";
import queryString from "query-string";
import { useInterval } from "usehooks-ts";

import styles from "./styles.module.scss";
import searchStyles from "../../../styles/module/search.module.scss";
import { DATE_FORMAT, STORAGE_KEY } from "../../../utils/constants";
import { EduSubject, Exam, StudentReviewInfo } from "../../../types/common";
import { getEduSubject } from "../../../api/exam";
import { useExamList, useSomeHaveSeatNo } from "../../../utils/hooks";
import DataGrid from "../../../components/DataGrid/DataGrid";
import {
  assignReviewQuestion,
  getReviewBase,
  getReviewQuestions,
} from "../../../api/review/review";
import {
  ReviewResultPayload,
  ReviewResult,
  ReviewQuestionDetail,
} from "../../../types/wrongQuestions";
import WrongQuestionDialog from "../../../components/WrongQuestion/WrongQuestionDialog/WrongQuestionDialog";
import AssignExamDialog from "../../../components/WrongQuestion/AssignExamDialog/AssignExamDialog";
import LoadingProgress from "../../../components/LoadingProgress/LoadingProgress";

const SELECT_ALL = "SELECT_ALL";
const DEFAULT_START_TIME = dayjs().subtract(6, "month").format(DATE_FORMAT);
const DEFAULT_END_TIME = dayjs().format(DATE_FORMAT);
const LATEST_REVIEW_TYPES = [
  { label: "歷史錯題", value: false },
  { label: "現有錯題", value: true },
];

const examTitleFormatter = (examList: Exam[], selectedExams: Exam[]) =>
  selectedExams.length === examList.length
    ? `所有測驗（${selectedExams.length}）`
    : selectedExams.map((exam) => exam.name).join("、");

const WrongQuestionsBase = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const haveFirstSearched = useRef(false);
  const [eduSubjectList, setEduSubjectList] = useState<EduSubject[]>([]);
  const [selectedStudents, setSelectedStudents] = useState<StudentReviewInfo[]>(
    []
  );
  const [submittedExams, setSubmittedExams] = useState<Exam[]>([]);
  const [isQuestionsDialogOpen, setIsQuestionsDialogOpen] = useState(false);
  const [questionsDialogDetail, setQuestionsDialogDetail] = useState<{
    questions: ReviewQuestionDetail[];
    title: string;
  }>({ questions: [], title: "" });
  const [isAssignExamDialogOpen, setIsAssignExamDialogOpen] = useState(false);
  const formik = useFormik({
    initialValues: {
      eduSubject: "",
      startTime:
        sessionStorage.getItem(STORAGE_KEY.startTime) || DEFAULT_START_TIME,
      endTime: sessionStorage.getItem(STORAGE_KEY.endTime) || DEFAULT_END_TIME,
      exams: [] as Exam[],
      isLatestReview: true,
    },
    onSubmit: (values) => {
      setSubmittedExams(values.exams);
      handleSubmit(values);
    },
  });
  const { eduSubject, startTime, endTime, exams, isLatestReview } =
    formik.values;
  const { examList } = useExamList({ eduSubject, startTime, endTime });
  const [currentExamList, setCurrentExamList] = useState<Exam[]>(examList);
  const [reviewBase, setReviewBase] = useState<ReviewResult | null>(null);
  const { isSomeHaveSeatNo } = useSomeHaveSeatNo(
    reviewBase?.studentInfos || []
  );
  const [isLoading, setIsLoading] = useState(true);
  const dataGridColumns: GridColDef<StudentReviewInfo>[] = useMemo(
    () => [
      {
        field: "seatNo",
        headerName: "座號",
        width: 76,
        headerAlign: "center",
        align: "center",
        cellClassName: styles.dataGrid__seatNo,
        sortable: false,
        valueFormatter: (params) => params.value || "-",
        hide: !isSomeHaveSeatNo,
      },
      { field: "name", headerName: "姓名", flex: 1, sortable: false },
      {
        field: "nonWRTotal",
        headerName: "剩餘錯題數量",
        align: "right",
        headerAlign: "right",
        width: 150,
      },
      {
        field: "action",
        headerName: "",
        align: "center",
        sortable: false,
        width: 112,
        renderCell: (params) => (
          <Button
            variant="outlined"
            onClick={() => {
              const { classId, institutionId } = queryString.parse(
                location.search
              );
              if (
                typeof classId !== "string" ||
                typeof institutionId !== "string"
              ) {
                console.error("取得資訊錯誤！");
                return;
              }
              getReviewQuestions({
                classId,
                institutionId,
                userId: params.row.userId,
                eduSubject,
                examIds: exams.map((exam) => exam.examId),
                isLatestReview,
              }).then((res) => {
                setIsQuestionsDialogOpen(true);
                setQuestionsDialogDetail({
                  questions: res.questionDetails,
                  title: `${params.row.name}－${examTitleFormatter(
                    currentExamList,
                    submittedExams
                  )}`,
                });
              });
            }}
          >
            查看試題
          </Button>
        ),
      },
    ],
    [
      isSomeHaveSeatNo,
      location.search,
      eduSubject,
      exams,
      currentExamList,
      submittedExams,
      isLatestReview,
    ]
  );

  const handleSubmit = (values: any) => {
    haveFirstSearched.current = true;
    const { classId, institutionId } = queryString.parse(location.search);
    if (typeof classId !== "string" || typeof institutionId !== "string") {
      console.error("取得資訊錯誤！");
      return;
    }
    setCurrentExamList(examList);

    eduSubject && localStorage.setItem("eduSubject", eduSubject);
    sessionStorage.setItem(STORAGE_KEY.startTime, startTime);
    sessionStorage.setItem(STORAGE_KEY.endTime, endTime);

    setIsLoading(true);
    const body: ReviewResultPayload = {
      classId,
      institutionId,
      examIds: values?.examIds || values.exams.map((exam: Exam) => exam.examId),
      isLatestReview: values.isLatestReview,
    };
    getReviewBase(body)
      .then(setReviewBase)
      .finally(() => setIsLoading(false));
  };

  const assignExams = () => {
    const { classId, institutionId } = queryString.parse(location.search);
    if (
      !reviewBase ||
      typeof classId !== "string" ||
      typeof institutionId !== "string"
    )
      return Promise.resolve();
    return assignReviewQuestion({
      eduSubject,
      classId,
      institutionId,
      jobId: reviewBase.jobId as string,
      students: selectedStudents.map((student) => ({
        name: student.name,
        seatNo: student.seatNo,
        userId: student.userId,
        userName: student.userName,
      })),
      service: localStorage.getItem(STORAGE_KEY.sourceService) || "oneLink",
      isLatestReview,
      examIds: exams.map((item) => item.examId),
    });
  };

  useEffect(() => {
    const { classId } = queryString.parse(location.search);
    if (typeof classId !== "string") return;
    getEduSubject(classId).then((res) => {
      setEduSubjectList(res);
      formik.setFieldValue(
        "eduSubject",
        localStorage.getItem("eduSubject") || res?.[0]?.code || ""
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    const { examIds } = queryString.parse(location.search);
    if (typeof examIds === "string") {
      handleSubmit({ examIds: examIds.split(",") });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    const query = queryString.parse(location.search);
    const { examIds } = query;
    if (examIds && examList.length > 0) {
      formik.setFieldValue(
        "exams",
        examList.filter((exam) => examIds.includes(exam.examId))
      );
      delete query.examIds;
      navigate(`${location.pathname}?${queryString.stringify(query)}`);
    } else {
      if (!haveFirstSearched.current) {
        formik.setFieldValue("exams", examList);
      } else {
        formik.setFieldValue(
          "exams",
          examList.filter((exam) =>
            exams.some((exam_) => exam_.examId === exam.examId)
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examList]);

  // 預設搜尋
  useEffect(() => {
    if (!exams.length) return;
    if (!haveFirstSearched.current) {
      handleSubmit(formik.values);
      setSubmittedExams(formik.values.exams);
    }
  }, [exams]);

  // 不讓使用者清除日期
  useEffect(() => {
    if (startTime === "") {
      formik.setFieldValue("startTime", endTime);
    }
    if (endTime === "") {
      formik.setFieldValue("endTime", startTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, endTime]);

  useInterval(
    () => {
      handleSubmit(formik.values);
    },
    reviewBase?.calculateStatus === "Pending" ? 3000 : null
  );

  return (
    <>
      <div className={styles.search}>
        <form
          className={searchStyles.search__inputs}
          onSubmit={formik.handleSubmit}
        >
          <TextField
            select
            name="eduSubject"
            label="學制科目"
            sx={{ width: 200 }}
            value={formik.values.eduSubject}
            onChange={(e) => {
              formik.setFieldValue("eduSubject", e.target.value);
              formik.setFieldValue("exams", []);
            }}
          >
            {eduSubjectList.map((item) => (
              <MenuItem key={item.code} value={item.code}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            type="date"
            name="startTime"
            label="開始日期"
            sx={{ width: 200 }}
            value={formik.values.startTime}
            onChange={formik.handleChange}
            InputProps={{ inputProps: { max: formik.values.endTime } }}
          />
          <TextField
            type="date"
            name="endTime"
            label="結束日期"
            sx={{ width: 200 }}
            value={formik.values.endTime}
            onChange={formik.handleChange}
            InputProps={{ inputProps: { min: formik.values.startTime } }}
          />
          <Autocomplete
            multiple
            sx={{ width: 412 }}
            limitTags={1}
            disableCloseOnSelect
            noOptionsText="查無測驗"
            options={
              examList.length > 0
                ? [
                    { examId: SELECT_ALL, name: "所有測驗", createTime: "" },
                    ...examList,
                  ]
                : []
            }
            isOptionEqualToValue={(option, value) =>
              option.examId === value.examId
            }
            getOptionLabel={(option) =>
              option.examId === SELECT_ALL
                ? "所有測驗"
                : `${dayjs(option.createTime).format("YYYY/MM/DD")} ${
                    option.name
                  }`
            }
            renderInput={(params) => <TextField {...params} label="測驗" />}
            value={formik.values.exams}
            onChange={(_, values, __, details) => {
              const isSelectAll = details?.option.examId === SELECT_ALL;
              const isClearAll =
                isSelectAll && formik.values.exams.length === examList.length;
              if (isClearAll) {
                formik.setFieldValue("exams", []);
              } else {
                formik.setFieldValue("exams", isSelectAll ? examList : values);
              }
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  style={{ height: "24px" }}
                  checked={
                    option.examId === SELECT_ALL
                      ? formik.values.exams.length === examList.length
                      : selected
                  }
                />
                {option.name}
              </li>
            )}
          />
          <LoadingButton
            type="submit"
            disabled={formik.values.exams.length === 0}
            loading={isLoading}
          >
            查詢
          </LoadingButton>
        </form>
      </div>
      <div className={styles.header}>
        <div className={styles.header__left}>
          <div className={styles.header__title}>
            {`有錯題學生${
              submittedExams.length > 0
                ? `－${examTitleFormatter(currentExamList, submittedExams)}`
                : ""
            }`}
          </div>
          <div className={styles.header__description}>
            勾選學生，派發複習錯題卷
          </div>
        </div>
        <div className={styles.header__right}>
          <div className={styles.header__right__toggle}>
            {LATEST_REVIEW_TYPES.map((type) => (
              <div
                key={type.label}
                className={isLatestReview === type.value ? styles.active : ""}
                onClick={() => {
                  if (isLoading || isLatestReview === type.value) return;
                  formik.setFieldValue("isLatestReview", type.value);
                  handleSubmit({
                    ...formik.values,
                    isLatestReview: type.value,
                  });
                }}
              >
                {type.label}
              </div>
            ))}
          </div>
          <Button
            disabled={selectedStudents.length === 0}
            onClick={() => setIsAssignExamDialogOpen(true)}
          >
            批次派錯題卷
          </Button>
        </div>
      </div>
      {!isLoading &&
        (!reviewBase || reviewBase?.calculateStatus === "Completed") && (
          <>
            {selectedStudents.length > 0 && (
              <div
                className={styles.selectedStudents}
              >{`已選取 ${selectedStudents.length} 位學生。`}</div>
            )}
            <DataGrid
              noDataText="尚無有錯題學生"
              rows={reviewBase?.studentInfos || []}
              columns={dataGridColumns}
              selectionModel={selectedStudents.map((student) => student.userId)}
              onSelectionModelChange={(ids) => {
                setSelectedStudents(
                  ids.map(
                    (id) =>
                      reviewBase?.studentInfos?.find(
                        (row: StudentReviewInfo) => row.userId === id
                      ) as StudentReviewInfo
                  )
                );
              }}
              getRowId={(row) => row.userId}
              checkboxSelection
            />
          </>
        )}
      {(reviewBase?.calculateStatus === "Pending" || isLoading) && (
        <LoadingProgress />
      )}
      <WrongQuestionDialog
        open={isQuestionsDialogOpen}
        onClose={() => setIsQuestionsDialogOpen(false)}
        questions={questionsDialogDetail.questions}
        title={questionsDialogDetail.title}
      />
      <AssignExamDialog
        open={isAssignExamDialogOpen}
        onClose={() => setIsAssignExamDialogOpen(false)}
        selectedStudents={selectedStudents}
        assignExams={assignExams}
      />
    </>
  );
};

export default WrongQuestionsBase;
