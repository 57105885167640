import { MetaDataErrorRate } from "../../types/common";
import styles from "./styles.module.scss";

type Type = "知識向度" | "認知向度";
type Props = {
  type: Type;
  data: MetaDataErrorRate[] | null;
};
const LIMIT = 10;

const descriptionMap = (type: Type) => {
  switch (type) {
    case "知識向度":
      return `此測驗答錯率排名前 ${LIMIT} 的知識向度`;
    case "認知向度":
      return "此測驗答錯的認知向度";
  }
};

const ErrorRateTable = ({ type, data }: Props) => {
  return (
    <div className={styles.errorRateTable}>
      <div className={styles.errorRateTable__description}>
        {descriptionMap(type)}
      </div>
      <div className={styles.table}>
        <div className={styles["table__row--head"]}>
          <div className={styles.table__type}>{type}</div>
          <div className={styles.table__rate}>答錯率</div>
        </div>
        <div className={styles.table__body}>
          {data && data.length > 0 ? (
            data.slice(0, LIMIT).map((datum) => (
              <div key={datum.index} className={styles["table__row--body"]}>
                <div className={styles.table__type}>{datum.name}</div>
                <div className={styles.table__rate}>{datum.errorRate} %</div>
              </div>
            ))
          ) : (
            <div className={styles.noData}>
              <img src="/assets/potted-plant.svg" alt="" />
              <span>沒有答錯的{type}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrorRateTable;
