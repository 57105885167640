import { forwardRef } from "react";
import {
  BaseVariant,
  CustomContentProps,
  SnackbarContent,
  useSnackbar,
} from "notistack";

import styles from "./styles.module.scss";
import CloseButton from "../CloseButton/CloseButton";

const iconMap = (variant: BaseVariant) => {
  switch (variant) {
    case "success":
      return <img src="/assets/checked-green.svg" alt="" />;
    case "error":
      return <img src="/assets/error-red.svg" alt="" />;
    default:
      return <></>;
  }
};

const Snackbar = forwardRef<HTMLDivElement, CustomContentProps>(
  ({ id, message, variant }, ref) => {
    const { closeSnackbar } = useSnackbar();

    return (
      <SnackbarContent ref={ref}>
        <div className={styles.snackbar}>
          <div className={styles.snackbar__icon}>{iconMap(variant)}</div>
          <div className={styles.snackbar__message}>{message}</div>
          <CloseButton onClose={() => closeSnackbar(id)} isInDialog={false} />
        </div>
      </SnackbarContent>
    );
  }
);

export default Snackbar;
