import { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { GridColDef } from "@mui/x-data-grid";
import { useFormik } from "formik";
import dayjs from "dayjs";
import queryString from "query-string";

import styles from "./styles.module.scss";
import searchStyles from "../../../styles/module/search.module.scss";
import { DATE_FORMAT, STORAGE_KEY } from "../../../utils/constants";
import { EduSubject, Exam } from "../../../types/common";
import { getEduSubject } from "../../../api/exam";
import DataGrid, {
  ROWS_PER_PAGE_OPTIONS,
} from "../../../components/DataGrid/DataGrid";
import { getReviewExam } from "../../../api/review/review";
import {
  ReviewExamResultPayload,
  ReviewExam,
} from "../../../types/wrongQuestions";
import { formatSecond, setEndDateTime } from "../../../utils/helpers";
import DownloadZip from "../../../components/WrongQuestion/DownloadZip/DownloadZip";

const DEFAULT_START_TIME = dayjs().subtract(6, "month").format(DATE_FORMAT);
const DEFAULT_END_TIME = dayjs().format(DATE_FORMAT);

const dataGridColumns: GridColDef<ReviewExam>[] = [
  {
    field: "createTime",
    headerName: "建立時間",
    width: 150,
    sortable: true,
    sortComparator: (a, b) => dayjs(a).diff(dayjs(b)),
    valueFormatter: (row) => dayjs(row.value).format("YYYY/MM/DD HH:mm"),
  },
  {
    field: "examName",
    headerName: "測驗名稱",
    flex: 1,
    sortable: false,
    renderCell: (params) => (
      <div className={styles.table__examName} title={params.row.examName || ""}>
        <div className={styles.table__examName__name}>
          {params.row.examName}
        </div>
        {dayjs(params.row.createTime).isSame(dayjs(), "date") && (
          <div className={styles.table__examName__newTag}>New</div>
        )}
      </div>
    ),
  },
  {
    field: "name",
    headerName: "測驗對象",
    width: 120,
    sortable: false,
  },
  {
    field: "questionTotal",
    headerName: "題數",
    width: 88,
    headerAlign: "right",
    align: "right",
    sortable: false,
  },
  {
    field: "wrongQuestionTotal",
    headerName: "答錯題數",
    width: 102,
    headerAlign: "right",
    align: "right",
    sortable: false,
    renderCell: (params) =>
      params.row.isCreated ? (
        params.row.wrongQuestionTotal
      ) : (
        <div className={styles["table__cell--disabled"]}>未交卷</div>
      ),
  },
  {
    field: "sumTime",
    headerName: "花費時長",
    width: 120,
    sortable: false,
    renderCell: (params) =>
      params.row.isCreated ? (
        formatSecond(params.row.sumTime || 0)
      ) : (
        <div className={styles["table__cell--disabled"]}>-</div>
      ),
  },
  {
    field: "action",
    headerName: "",
    sortable: false,
    flex: 1,
    renderCell: (params) => (
      <>
        <Button
          variant="outlined"
          style={{ marginRight: "12px" }}
          onClick={() =>
            window.open(
              `${process.env.REACT_APP_ONEEXAM_URL}/paper/preview/${params.row.paperId}`
            )
          }
        >
          檢視試卷
        </Button>
        <Button
          onClick={() =>
            window.open(
              `${process.env.REACT_APP_ONEEXAM_URL}/user/report/${params.row.examId}/${params.row.userName}`
            )
          }
          disabled={!params.row.isCreated}
        >
          測驗結果
        </Button>
        {params.row?.paperDownloadPath && (
          <DownloadZip fileUrl={params.row.paperDownloadPath} />
        )}
      </>
    ),
  },
];

const WrongQuestionExams = () => {
  const location = useLocation();
  const [eduSubjectList, setEduSubjectList] = useState<EduSubject[]>([]);
  const formik = useFormik({
    initialValues: {
      eduSubject: "" as EduSubject["code"],
      startTime:
        sessionStorage.getItem(STORAGE_KEY.startTime) || DEFAULT_START_TIME,
      endTime: sessionStorage.getItem(STORAGE_KEY.endTime) || DEFAULT_END_TIME,
      exams: [] as Exam[],
      isOnlyShowNotFinished: false,
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const { startTime, endTime, isOnlyShowNotFinished } = formik.values;
  const [isLoading, setIsLoading] = useState(false);
  const [reviewExams, setReviewExams] = useState<ReviewExam[]>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(ROWS_PER_PAGE_OPTIONS[0]);
  const [totalCount, setTotalCount] = useState(0);

  const handleSubmit = useCallback(
    (values: any) => {
      const { eduSubject, startTime, endTime } = values;
      if (!eduSubject) return;
      const { classId, institutionId } = queryString.parse(location.search);
      if (typeof classId !== "string" || typeof institutionId !== "string") {
        console.error("取得資訊錯誤！");
        return;
      }

      eduSubject && localStorage.setItem("eduSubject", eduSubject);
      sessionStorage.setItem(STORAGE_KEY.startTime, startTime);
      sessionStorage.setItem(STORAGE_KEY.endTime, endTime);

      const body: ReviewExamResultPayload = {
        classId,
        institutionId,
        eduSubject,
        startTime,
        endTime: setEndDateTime(endTime),
        pageNumber: page + 1,
        pageSize,
        service: localStorage.getItem(STORAGE_KEY.sourceService) || "",
      };
      if (isOnlyShowNotFinished) body.isNonCreated = true;
      setIsLoading(true);
      getReviewExam(body)
        .then((res) => {
          setReviewExams(
            res?.reviewExams?.sort((a, b) =>
              dayjs(b.createTime).diff(dayjs(a.createTime))
            ) || []
          );
          setTotalCount(res.totalCount);
        })
        .finally(() => setIsLoading(false));
    },
    [location.search, page, pageSize, isOnlyShowNotFinished]
  );

  useEffect(() => {
    const { classId } = queryString.parse(location.search);
    if (typeof classId !== "string") return;
    getEduSubject(classId).then((res) => {
      setEduSubjectList(res);
      const defaultEduSubject =
        localStorage.getItem("eduSubject") || res?.[0]?.code || "";
      formik.setFieldValue("eduSubject", defaultEduSubject);
      handleSubmit({ ...formik.values, eduSubject: defaultEduSubject });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  // 不讓使用者清除日期
  useEffect(() => {
    if (startTime === "") {
      formik.setFieldValue("startTime", endTime);
    }
    if (endTime === "") {
      formik.setFieldValue("endTime", startTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, endTime]);

  useEffect(() => {
    handleSubmit(formik.values);
  }, [handleSubmit]);

  return (
    <>
      <div className={styles.search}>
        <form
          className={searchStyles.search__inputs}
          onSubmit={formik.handleSubmit}
        >
          <TextField
            select
            name="eduSubject"
            label="學制科目"
            sx={{ width: 200 }}
            value={formik.values.eduSubject}
            onChange={formik.handleChange}
          >
            {eduSubjectList.map((item) => (
              <MenuItem key={item.code} value={item.code}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            type="date"
            name="startTime"
            label="開始日期"
            sx={{ width: 200 }}
            value={formik.values.startTime}
            onChange={formik.handleChange}
            InputProps={{ inputProps: { max: formik.values.endTime } }}
          />
          <TextField
            type="date"
            name="endTime"
            label="結束日期"
            sx={{ width: 200 }}
            value={formik.values.endTime}
            onChange={formik.handleChange}
            InputProps={{ inputProps: { min: formik.values.startTime } }}
          />
          <LoadingButton type="submit" loading={isLoading}>
            查詢
          </LoadingButton>
        </form>
      </div>
      <div className={styles.header}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                className={styles.header__checkbox}
                name="isOnlyShowNotFinished"
                value={formik.values.isOnlyShowNotFinished}
                onChange={(e) => {
                  formik.setFieldValue(
                    "isOnlyShowNotFinished",
                    e.target.checked
                  );
                  setPage(0);
                }}
              />
            }
            label="只顯示未交卷的測驗"
            style={{ userSelect: "none" }}
          />
        </FormGroup>
      </div>
      <DataGrid
        noDataText="尚無錯題卷"
        rows={reviewExams}
        columns={dataGridColumns}
        getRowId={(row) => row.examId}
        customPage={page}
        customSetPage={setPage}
        customPageSize={pageSize}
        customSetPageSize={setPageSize}
        customCount={totalCount}
      />
    </>
  );
};

export default WrongQuestionExams;
