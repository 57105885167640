import Api from "..";
import {
  ReviewResult,
  ReviewResultPayload,
  ReviewQuestionList,
  ReviewQuestionsPayload,
  AssignReviewQuestionPayload,
  ReviewExamResultPayload,
  ReviewExamResult,
  ReviewStudentFilterPayload,
  ReviewStudentFilter,
  ReviewStudentExamPayload,
  ReviewStudentExam,
  ReviewStudentErrorNumber,
  ReviewStudentErrorNumberPayload,
  ReviewStudentEduSubject,
  ReviewStudentRangePayload,
  ReviewStudentAssign,
  ReviewStudentAssignPayload,
  ReviewStudentRangeData,
  ReviewStudentCalculateResponse,
} from "../../types/wrongQuestions";

import { InstitutionInfo } from "../../types/common";

const API_URL = process.env.REACT_APP_API_URL;

export const getReviewBase = async (payload: ReviewResultPayload) =>
  (await Api.post<ReviewResult>(`${API_URL}/api/Review/students`, payload))
    .data;

export const getReviewQuestions = async (payload: ReviewQuestionsPayload) =>
  (
    await Api.post<ReviewQuestionList>(
      `${API_URL}/api/Review/questions`,
      payload
    )
  ).data;

export const assignReviewQuestion = async (
  payload: AssignReviewQuestionPayload
) => await Api.post<string[]>(`${API_URL}/api/Review/assign`, payload);

export const getReviewExam = async (payload: ReviewExamResultPayload) =>
  (await Api.post<ReviewExamResult>(`${API_URL}/api/Review/exams`, payload))
    .data;

export const getReviewStudentFilter = async (
  payload: ReviewStudentFilterPayload
) =>
  (
    await Api.post<ReviewStudentFilter>(
      `${API_URL}/api/Review/exams/studentfilter`,
      payload
    )
  ).data;

export const getReviewStudentExam = async (payload: ReviewStudentExamPayload) =>
  (
    await Api.post<ReviewStudentExam>(
      `${API_URL}/api/Review/exams/student`,
      payload
    )
  ).data;

export const getReviewStudentErrorNumber = async (
  payload: ReviewStudentErrorNumberPayload
) =>
  (
    await Api.post<ReviewStudentErrorNumber>(
      `${API_URL}/api/Review/exams/student/questiontotal`,
      payload
    )
  ).data;

export const getReviewStudentEduSubject = async (payload: InstitutionInfo) =>
  (
    await Api.post<ReviewStudentEduSubject>(
      `${API_URL}/api/Review/student/edusubject`,
      payload
    )
  ).data;

export const getReviewStudentRange = async (
  payload: ReviewStudentRangePayload
) =>
  await Api.post<ReviewStudentRangeData>(
    `${API_URL}/api/Review/exams/student/range`,
    payload
  );

export const getReviewStudentAssign = async (
  payload: ReviewStudentAssignPayload
) =>
  (
    await Api.post<ReviewStudentAssign>(
      `${API_URL}/api/Review/exams/studentrange/assign`,
      payload
    )
  ).data;

export const getReviewStudentCalculate = async (payload: InstitutionInfo) =>
  await Api.post<ReviewStudentCalculateResponse>(
    `${API_URL}/api/Review/exams/student/calculate`,
    payload
  );
