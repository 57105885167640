import { useState, useMemo, Dispatch, SetStateAction } from "react";
import { DataGrid as MDataGrid, DataGridProps } from "@mui/x-data-grid";

import styles from "./styles.module.scss";
import CustomPagination from "./TablePagination";

export const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];

type Props = {
  noDataText: string;
  // for server pagination
  customPageSize?: number;
  customSetPageSize?: Dispatch<SetStateAction<number>>;
  customPage?: number;
  customSetPage?: Dispatch<SetStateAction<number>>;
  customCount?: number;
} & DataGridProps;

const DataGrid = ({
  rows,
  columns,
  noDataText,
  customPageSize,
  customSetPageSize,
  customPage,
  customSetPage,
  customCount,
  ...props
}: Props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_OPTIONS[0]);
  const noData = useMemo(() => rows.length === 0, [rows]);

  return (
    <MDataGrid
      rows={rows}
      columns={columns}
      page={customPage || page}
      pageSize={customPageSize || rowsPerPage}
      disableSelectionOnClick
      disableColumnMenu
      hideFooterSelectedRowCount
      hideFooter={noData}
      components={{
        Pagination: () => (
          <CustomPagination
            count={customCount || rows.length}
            page={customPage || page}
            setPage={customSetPage || setPage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            rowsPerPage={customPageSize || rowsPerPage}
            setRowsPerPage={(newPage) => {
              customSetPageSize
                ? customSetPageSize(newPage)
                : setRowsPerPage(newPage);
              customSetPage ? customSetPage(0) : setPage(0);
            }}
          />
        ),
        NoRowsOverlay: () => (
          <div className={styles.noData}>
            <img src="/assets/potted-plant.svg" alt="" />
            <span>{noDataText}</span>
          </div>
        ),
      }}
      sx={{
        ".MuiDataGrid-virtualScrollerContent": noData
          ? { height: "400px !important" }
          : {},
      }}
      {...props}
    />
  );
};

export default DataGrid;
