import { useState, useCallback } from "react";
import { Button, Pagination } from "@mui/material";

import { StudentInfoForExam } from "../../../types/common";
import styles from "./styles.module.scss";
import { formatSecond } from "../../../utils/helpers";
import { useSomeHaveSeatNo } from "../../../utils/hooks";

type Props = {
  students: StudentInfoForExam[];
  examId: string;
};

const STUDENTS_PER_PAGE = 10;
const SEAT_NO = "seatNo";
const SCORE = "score";
type SortType = typeof SEAT_NO | typeof SCORE;
const ASC = "asc";
const DESC = "desc";
type SortOrder = "" | typeof ASC | typeof DESC;

const ExamStudentList = ({ students, examId }: Props) => {
  const [page, setPage] = useState(1);
  const [sortType, setSortType] = useState<SortType>(SEAT_NO);
  const [sortOrder, setSortOrder] = useState<SortOrder>("");
  const { isSomeHaveSeatNo } = useSomeHaveSeatNo(students || []);

  const handleChangePage = (_: any, value: number) => {
    setPage(value);
  };

  const handleSort = useCallback(
    (type: SortType) => {
      switch (`${type}+${sortType}`) {
        case `${SEAT_NO}+${SEAT_NO}`:
        case `${SCORE}+${SCORE}`:
          setSortOrder((prev) => {
            switch (prev) {
              case "":
                return ASC;
              case ASC:
                return DESC;
              case DESC:
                return "";
            }
          });
          return;
        case `${SEAT_NO}+${SCORE}`:
          setSortType(type);
          setSortOrder(ASC);
          return;
        case `${SCORE}+${SEAT_NO}`:
          setSortType(type);
          setSortOrder(DESC);
          return;
      }
    },
    [sortType]
  );

  return (
    <div className={styles.table}>
      <div className={styles["table__row--head"]}>
        {isSomeHaveSeatNo && (
          <div className={styles.seatNo} onClick={() => handleSort(SEAT_NO)}>
            <span>座號</span>
            <div className={styles.score__order}>
              <img
                src="/assets/caret-up.svg"
                alt=""
                style={{
                  opacity: sortType === SEAT_NO && sortOrder === ASC ? 1 : 0.5,
                }}
              />
              <img
                src="/assets/caret-down.svg"
                alt=""
                style={{
                  opacity: sortType === SEAT_NO && sortOrder === DESC ? 1 : 0.5,
                }}
              />
            </div>
          </div>
        )}
        <div className={styles.name}>姓名</div>
        <div className={styles.score} onClick={() => handleSort(SCORE)}>
          <span>得分</span>
          <div className={styles.score__order}>
            <img
              src="/assets/caret-up.svg"
              alt=""
              style={{
                opacity: sortType === SCORE && sortOrder === ASC ? 1 : 0.5,
              }}
            />
            <img
              src="/assets/caret-down.svg"
              alt=""
              style={{
                opacity: sortType === SCORE && sortOrder === DESC ? 1 : 0.5,
              }}
            />
          </div>
        </div>
        <div className={styles.spendTime}>花費時長</div>
        <div className={styles.action} />
      </div>
      <div className={styles.table__body}>
        {students
          // eslint-disable-next-line array-callback-return
          .sort((a, b) => {
            switch (sortType) {
              case SEAT_NO:
                switch (sortOrder) {
                  case "":
                  case ASC:
                    return Number(a.seatNo) - Number(b.seatNo);
                  case DESC:
                    return Number(b.seatNo) - Number(a.seatNo);
                }
                break;
              case SCORE:
                const scoreA = a.score ?? -1;
                const scoreB = b.score ?? -1;
                switch (sortOrder) {
                  case "":
                    return Number(a.seatNo) - Number(b.seatNo);
                  case ASC:
                    return scoreA - scoreB;
                  case DESC:
                    return scoreB - scoreA;
                }
                break;
            }
          })
          .slice((page - 1) * STUDENTS_PER_PAGE, page * STUDENTS_PER_PAGE)
          .map((student) => (
            <div key={student.userName} className={styles["table__row--body"]}>
              {isSomeHaveSeatNo && (
                <div className={styles.seatNo}>{student.seatNo || "-"}</div>
              )}
              <div className={styles.name}>{student.name}</div>
              <div className={styles.score}>
                {student.isCreated ? (
                  student.score
                ) : (
                  <span className={styles.noRecord}>無交卷紀錄</span>
                )}
              </div>
              <div className={styles.spendTime}>
                {student.isCreated ? formatSecond(student.sumTime) : "-"}
              </div>
              <div className={styles.action}>
                <Button
                  variant="outlined"
                  color="inherit"
                  sx={{ padding: "4px 12px", height: "32px" }}
                  disabled={!student.isCreated}
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_ONEEXAM_URL}/user/report/${examId}/${student.userName}`
                    )
                  }
                >
                  測驗結果
                </Button>
              </div>
            </div>
          ))}
      </div>
      <div className={styles.table__pagination}>
        <Pagination
          shape="rounded"
          count={Math.ceil(students.length / STUDENTS_PER_PAGE)}
          page={page}
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
};

export default ExamStudentList;
