import { useMemo } from "react";
import { Tooltip } from "@mui/material";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";

import styles from "./styles.module.scss";
import { StudentReportResult } from "../../../types/statistics";
import { StudentExamDistribution } from "../../../types/common";

const BAR_CHART_COLOR = "#dbebed";
const LINE_CHART_COLOR = "#0094ff";
const xAxisLabelMap = (distribution: StudentExamDistribution) => {
  const nameLength = distribution.name?.length;
  let name = [distribution.name?.slice(0, 4) || ""];
  if (nameLength > 4 && nameLength < 8) {
    name.push(distribution.name?.slice(4, 7));
  } else if (nameLength >= 8) {
    name.push(distribution.name?.slice(4, 7) + "...");
  } else {
    name.push(" ");
  }
  name.push("");
  return [distribution.createDate, ...name];
};
const columnsWidthPercentage = (n: number) => {
  const percentWidth = Math.round((24 / (1100 / n)) * 100);
  return `${percentWidth}%`;
};

type Props = {
  statistics: StudentReportResult;
};

const StudentDistributionChartForTeacher = ({ statistics }: Props) => {
  const chartWidth = useMemo(() => {
    if (!statistics?.distributions) return 1100;
    return statistics.distributions.length <= 14
      ? 1100
      : statistics.distributions.length * 81;
  }, [statistics]);
  const barChartData = useMemo(
    () =>
      statistics?.distributions?.map((distribution) => ({
        x: xAxisLabelMap(distribution),
        y: distribution.isCreated ? distribution.scoreRate : 0,
      })) || [],
    [statistics]
  );
  const lineChartData = useMemo(
    () =>
      statistics?.distributions?.map((distribution) => ({
        x: xAxisLabelMap(distribution),
        y: distribution.isCreated ? distribution.pr : 0,
      })) || [],
    [statistics]
  );
  const chartOptions: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    legend: {
      show: false,
      offsetY: 10,
      fontSize: "14px",
      itemMargin: { horizontal: 24 },
      onItemClick: {
        toggleDataSeries: false,
      },
      markers: {
        radius: 0,
        offsetX: -2,
        offsetY: -1,
      },
    },
    markers: {
      strokeWidth: 0,
      colors: LINE_CHART_COLOR,
      size: 5,
      hover: {
        size: 5,
      },
    },
    xaxis: {
      labels: {
        style: {
          colors: "#8b90a0",
          fontSize: "14px",
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: [
      {
        max: 100,
        min: 0,
        tickAmount: 5,
        labels: {
          style: {
            colors: "#8b90a0",
            fontSize: "14px",
          },
          formatter: (v) => `${v}%`,
        },
      },
      {
        opposite: true,
        max: 100,
        min: 0,
        tickAmount: 5,
        labels: {
          style: {
            colors: "#8b90a0",
            fontSize: "14px",
          },
          formatter: (value) => value.toFixed(0),
        },
      },
    ],
    plotOptions: {
      bar: {
        columnWidth: columnsWidthPercentage(
          statistics?.distributions?.length || 0
        ),
      },
    },
    stroke: {
      colors: [BAR_CHART_COLOR, LINE_CHART_COLOR],
      width: 1,
    },
    tooltip: {
      custom: (option) => {
        if (!statistics?.distributions) return "";
        const { name, isCreated, scoreRate, pr } =
          statistics.distributions[option.dataPointIndex];
        return `<div class="customTooltip">
            <div class="customTooltip__title">${name || ""}</div>
            <div class="customTooltip__list">
              <div class="customTooltip__item">
                <div class="customTooltip__dot customTooltip__lightGreen"></div>
                <div class="customTooltip__type">得分率</div>
                <div class="customTooltip__value">${
                  isCreated ? `${scoreRate}%` : "無交卷紀錄"
                }</div>
              </div>
              <div class="customTooltip__item">
                <div class="customTooltip__dot customTooltip__blue"></div>
                <div class="customTooltip__type">班級 PR 值</div>
                <div class="customTooltip__value">${isCreated ? pr : "-"}</div>
              </div>
            </div>
          </div>`;
      },
    },
  };
  const chartSeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        type: "bar",
        name: "得分率",
        data: barChartData,
        color: BAR_CHART_COLOR,
      },
      {
        type: "line",
        name: "班級 PR 值",
        data: lineChartData || [],
        color: LINE_CHART_COLOR,
      },
    ],
    [barChartData, lineChartData]
  );

  return (
    <>
      <div
        className={styles.yAxisTitleGroup}
        style={{
          width: chartWidth - 30,
        }}
      >
        <div className={styles.yAxisTitle}>
          得分率（％）
          <Tooltip title="得分除以總分">
            <img src="/assets/question-mark.svg" alt="" />
          </Tooltip>
        </div>
        <div className={styles.yAxisTitle}>
          班級 PR 值
          <Tooltip title="班級排名百分比">
            <img src="/assets/question-mark.svg" alt="" />
          </Tooltip>
        </div>
      </div>
      <Chart
        options={chartOptions}
        series={chartSeries}
        width={chartWidth}
        height="400px"
      />
      <div className={styles.legend}>
        <div className={styles.legend__item}>
          <div
            className={styles["legend__icon--bar"]}
            style={{ backgroundColor: BAR_CHART_COLOR }}
          ></div>
          <div className={styles.legend__label}>得分率</div>
        </div>
        <div className={styles.legend__item}>
          <div
            className={styles["legend__icon--line"]}
            style={{ backgroundColor: LINE_CHART_COLOR }}
          ></div>
          <div className={styles.legend__label}>班級 PR 值</div>
        </div>
      </div>
    </>
  );
};

export default StudentDistributionChartForTeacher;
