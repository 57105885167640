import { CircularProgress } from "@mui/material";
import styles from "./styles.module.scss";

const LoadingProgress = () => {
  return (
    <div className={styles.loadingProgress}>
      <CircularProgress size={64} />
      <div>載入中</div>
    </div>
  );
};

export default LoadingProgress;
