import styles from "./styles.module.scss";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      Made with Copyright © NANI ENTERPRISE CO.,LTD. All Rights Reserved.
    </footer>
  );
};

export default Footer;
