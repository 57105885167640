import styles from "./styles.module.scss";

type Props = {
  data: { title: React.ReactNode; value: React.ReactNode }[];
};

const GroupCard = ({ data }: Props) => {
  return (
    <ul className={styles.groupCard}>
      {data.map((datum) => (
        <li key={datum.title?.toString()} className={styles.groupCard__item}>
          <div className={styles.groupCard__title}>{datum.title}</div>
          <div className={styles.groupCard__value}>{datum.value}</div>
        </li>
      ))}
    </ul>
  );
};

export default GroupCard;
