import { useState, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Button,
  Checkbox,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import dayjs from "dayjs";
import queryString from "query-string";
import { useInterval } from "usehooks-ts";

import styles from "./styles.module.scss";
import searchStyles from "../../styles/module/search.module.scss";
import Card from "../../components/Block/Block";
import { getClassStatistics } from "../../api/statistics";
import {
  ClassReportResult,
  ClassReportResultPayload,
} from "../../types/statistics";
import { EduSubject, Exam } from "../../types/common";
import GroupCard from "../../components/GroupCard/GroupCard";
import ClassDistributionChart from "../../components/Charts/ClassDistributionChart/ClassDistributionChart";
import ErrorRateTable from "../../components/ErrorRateTable/ErrorRateTable";
import ErrorQuestionsList from "../../components/ErrorQuestionsList/ErrorQuestionsList";
import ClassStudentList from "../../components/StudentList/ClassStudentList/ClassStudentList";
import ReportGenerating from "../../components/ReportGenerating/ReportGenerating";
import { DATE_FORMAT, STORAGE_KEY } from "../../utils/constants";
import { getEduSubject } from "../../api/exam";
import { useClassInfo, useExamList } from "../../utils/hooks";

const GAP = 24;
const SELECT_ALL = "SELECT_ALL";
const DEFAULT_START_TIME = dayjs().subtract(6, "month").format(DATE_FORMAT);
const DEFAULT_END_TIME = dayjs().format(DATE_FORMAT);

const ClassReport = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const haveFirstSearched = useRef(false);
  const [eduSubjectList, setEduSubjectList] = useState<EduSubject[]>([]);
  const formik = useFormik({
    initialValues: {
      eduSubject: "" as EduSubject["code"],
      startTime:
        sessionStorage.getItem(STORAGE_KEY.startTime) || DEFAULT_START_TIME,
      endTime: sessionStorage.getItem(STORAGE_KEY.endTime) || DEFAULT_END_TIME,
      exams: [] as Exam[],
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const { eduSubject, startTime, endTime, exams } = formik.values;
  const { examList } = useExamList({ eduSubject, startTime, endTime });
  const { classInfo } = useClassInfo();
  const [isLoading, setIsLoading] = useState(false);
  const [statistics, setStatistics] = useState<ClassReportResult | null>(null);
  const groupCardData = useMemo(
    () => [
      {
        title: "測驗份數",
        value: String(statistics?.examBasicInfo?.examTotal),
      },
      {
        title: "最建議補強的知識向度",
        value: statistics?.examBasicInfo?.knowledgeName || "-",
      },
      {
        title: "最建議補強的認知向度",
        value: statistics?.examBasicInfo?.cognitiveName || "-",
      },
    ],
    [statistics]
  );

  const deleteExamIdsInQuery = () => {
    const query = queryString.parse(location.search);
    delete query.examIds;
    navigate(`${location.pathname}?${queryString.stringify(query)}`);
  };

  const handleSubmit = (values: any) => {
    haveFirstSearched.current = true;
    const { classId, institutionId } = queryString.parse(location.search);
    if (typeof classId !== "string" || typeof institutionId !== "string") {
      console.error("取得資訊錯誤！");
      return;
    }

    localStorage.setItem(STORAGE_KEY.eduSubject, eduSubject);
    sessionStorage.setItem(STORAGE_KEY.startTime, startTime);
    sessionStorage.setItem(STORAGE_KEY.endTime, endTime);

    setIsLoading(true);
    const body: ClassReportResultPayload = {
      classId,
      institutionId,
      examIds: values?.examIds || values.exams.map((exam: Exam) => exam.examId),
    };
    getClassStatistics(body)
      .then(setStatistics)
      .finally(() => {
        setIsLoading(false);
        deleteExamIdsInQuery();
      });
  };

  useEffect(() => {
    const { classId } = queryString.parse(location.search);
    if (typeof classId !== "string") return;
    getEduSubject(classId).then((res) => {
      setEduSubjectList(res);
      formik.setFieldValue(
        "eduSubject",
        localStorage.getItem(STORAGE_KEY.eduSubject) || res?.[0]?.code || ""
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    // 用 url 裡的 examIds 搜尋報表（有 classInfo 才執行）
    if (!classInfo) return;
    const { examIds } = queryString.parse(location.search);
    if (typeof examIds === "string" && examIds) {
      handleSubmit({ ...formik.values, examIds: examIds.split(",") });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, classInfo]);

  useEffect(() => {
    const { examIds } = queryString.parse(location.search);
    if (examIds && examList.length > 0) {
      formik.setFieldValue(
        "exams",
        examList.filter((exam) => examIds.includes(exam.examId))
      );
    } else {
      if (!haveFirstSearched.current) {
        formik.setFieldValue("exams", examList);
      } else {
        formik.setFieldValue(
          "exams",
          examList.filter((exam) =>
            exams.some((exam_) => exam_.examId === exam.examId)
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, examList]);

  // 預設搜尋
  useEffect(() => {
    if (!exams.length) return;
    if (!haveFirstSearched.current) {
      handleSubmit(formik.values);
    }
  }, [exams]);

  // 不讓使用者清除日期
  useEffect(() => {
    if (startTime === "") {
      formik.setFieldValue("startTime", endTime);
    }
    if (endTime === "") {
      formik.setFieldValue("endTime", startTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, endTime]);

  useInterval(
    () => {
      handleSubmit(formik.values);
    },
    statistics?.calculateStatus === "Pending" ? 3000 : null
  );

  return (
    <div className={styles.container} style={{ gap: GAP }}>
      <div className={searchStyles.search}>
        <div className={searchStyles.search__title}>
          <span className={searchStyles.search__title__type}>
            班級綜合測驗報表
          </span>
          <span>{classInfo?.name ? `｜${classInfo.name}` : ""}</span>
        </div>
        <form
          className={searchStyles.search__inputs}
          onSubmit={formik.handleSubmit}
        >
          <TextField
            select
            name="eduSubject"
            label="學制科目"
            sx={{ width: 200 }}
            value={formik.values.eduSubject}
            onChange={(e) => {
              formik.setFieldValue("eduSubject", e.target.value);
              formik.setFieldValue("exams", []);
            }}
          >
            {eduSubjectList.map((item) => (
              <MenuItem key={item.code} value={item.code}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          <div className={searchStyles.divider} />
          <TextField
            type="date"
            name="startTime"
            label="開始日期"
            sx={{ width: 200 }}
            value={formik.values.startTime}
            onChange={formik.handleChange}
            InputProps={{ inputProps: { max: formik.values.endTime } }}
          />
          <TextField
            type="date"
            name="endTime"
            label="結束日期"
            sx={{ width: 200 }}
            value={formik.values.endTime}
            onChange={formik.handleChange}
            InputProps={{ inputProps: { min: formik.values.startTime } }}
          />
          <Autocomplete
            multiple
            sx={{ flex: 1 }}
            limitTags={1}
            disableCloseOnSelect
            noOptionsText="查無測驗"
            options={
              examList.length > 0
                ? [
                    { examId: SELECT_ALL, name: "所有測驗", createTime: "" },
                    ...examList,
                  ]
                : []
            }
            isOptionEqualToValue={(option, value) =>
              option.examId === value.examId
            }
            getOptionLabel={(option) =>
              option.examId === SELECT_ALL
                ? "所有測驗"
                : `${dayjs(option.createTime).format("YYYY/MM/DD")} ${
                    option.name
                  }`
            }
            renderInput={(params) => <TextField {...params} label="測驗" />}
            value={formik.values.exams}
            onChange={(_, values, __, details) => {
              const isSelectAll = details?.option.examId === SELECT_ALL;
              const isClearAll =
                isSelectAll && formik.values.exams.length === examList.length;
              if (isClearAll) {
                formik.setFieldValue("exams", []);
              } else {
                formik.setFieldValue("exams", isSelectAll ? examList : values);
              }
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  style={{ height: "24px" }}
                  checked={
                    option.examId === SELECT_ALL
                      ? formik.values.exams.length === examList.length
                      : selected
                  }
                />
                {option.name}
              </li>
            )}
          />
          <LoadingButton
            type="submit"
            disabled={formik.values.exams.length === 0}
            loading={isLoading}
          >
            查詢
          </LoadingButton>
        </form>
      </div>
      {statistics && statistics.calculateStatus === "Completed" ? (
        <>
          <div className={styles.about}>
            <div>
              <div>
                <div className={styles.about__eduSubject}>
                  {statistics?.examBasicInfo?.eduSubjectName}
                </div>
                <div className={styles.about__classInfo}>
                  <span>班級人數</span>
                  <span>{statistics?.classInfo?.studentTotal}人</span>
                </div>
                <div className={styles.about__classInfo}>
                  <span>授課老師</span>
                  <span>{statistics?.classInfo?.teacherNames?.join("、")}</span>
                </div>
              </div>
              <div className={styles.about__scoreRate}>
                <div className={styles.about__scoreRate__title}>
                  <span>平均得分率</span>
                  <Tooltip title="每場測驗得分除以總分，加總取平均值">
                    <img src="/assets/question-mark.svg" alt="" />
                  </Tooltip>
                </div>
                <div className={styles.about__scoreRate__content}>
                  <img
                    className={styles.about__scoreRate__icon}
                    src="/assets/trophy.svg"
                    alt=""
                  />
                  <div className={styles.about__scoreRate__percentage}>
                    <span>{statistics?.examBasicInfo?.avgScoreRate}</span>%
                  </div>
                </div>
              </div>
            </div>
            <GroupCard data={groupCardData} />
          </div>
          <Card title="測驗成績分布">
            <div className={styles.distribution}>
              <div className={styles.charts}>
                <ClassDistributionChart statistics={statistics} />
              </div>
              <div className={styles.distributionExample}>
                <div className={styles.distributionExample__header}>
                  <div>分數標準</div>
                  <div className={styles.distributionExample__header__pr}>
                    <span>PR 值區段</span>
                    <Tooltip title="將班級排名百分比，分成四個區段">
                      <img src="/assets/question-mark.svg" alt="" />
                    </Tooltip>
                  </div>
                </div>
                <img src="/assets/distribution-example.svg" alt="" />
              </div>
            </div>
          </Card>
          <Card title="知識向度答錯率" width={`calc(50% - ${GAP / 2}px)`}>
            <ErrorRateTable
              type="知識向度"
              data={statistics.knowledgeErrorRates}
            />
          </Card>
          <Card title="認知向度答錯率" width={`calc(50% - ${GAP / 2}px)`}>
            <ErrorRateTable
              type="認知向度"
              data={statistics.cognitiveErrorRates}
            />
          </Card>
          {statistics?.questionInfos?.length && (
            <Card title="錯題排行">
              <ErrorQuestionsList questions={statistics.questionInfos} />
            </Card>
          )}
          <Card
            title="學生列表"
            toolbar={
              <Button
                onClick={() =>
                  window.open(
                    `/review${location.search}&examIds=${exams
                      .map((exam) => exam.examId)
                      .join(",")}`
                  )
                }
              >
                前往派錯題卷
              </Button>
            }
          >
            <ClassStudentList
              students={statistics.studentInfos || []}
              examIds={exams.map((exam) => exam.examId)}
              jobId={statistics.jobId}
            />
          </Card>
        </>
      ) : (
        <ReportGenerating />
      )}
    </div>
  );
};

export default ClassReport;
